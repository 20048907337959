/**
 * Login container component responsible for acting as a container for the Login Page.
 * @namespace LoginContainer
 * @memberOf Login
 */
import React from "react"
import LoginDisplay from "components/Login/LoginDisplay.component"
/**
 * Renders the Login container component.
 * This component is responsible for containing content on the Login page.
 * @returns {React.ReactNode} The rendered LoginContainer component.
 */

const LoginContainer = () => {
  return (
    <div id="login" className="flex flex-row h-full">
      <div className="flex flex-col mx-auto my-auto p-36 rounded-lg shadow-md">
        <LoginDisplay />
      </div>
    </div>
  )
}

export default LoginContainer
