import axios from "../request"

/**
 * Fetches the list of projects.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @param {string} filter - The filter to apply to the projects - owned, member, both, all.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function getProjects(token, user, unit, role, filter) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(
    `/available-projects?filter=${filter}&metadata=true`,
    config
  )
  return response
}

/**
 * Fetches information for a specific project.
 *
 * @param {string} token - The authorization token.
 * @param {string} id - The project ID.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function getProjectInfo(token, user, unit, role, id) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(
    `/project-get?id=${id}&metadata=true`,
    config
  )
  return response
}

/**
 * Creates a new project.
 *
 * @param {string} token - The authorization token.
 * @param {Object} body - The body of the request containing project details.
 * @param {string} body.name - The name of the project.
 * @param {string} body.goal - The goal of the project.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function createProject(token, user, unit, role, body) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.post(
    `/project-create`,
    {
      name: body.name,
      goal: body.goal,
      type: "SURVEY",
      status: "DESIGNING",
      languages: ["English"],
      designLanguage: "English",
    },
    config
  )
  return response
}

/**
 * Deletes a project.
 *
 * @param {string} token - The authorization token.
 * @param {string} id - The project ID.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function deleteProject(token, user, unit, role, id) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.delete(`/project-delete?id=${id}`, config)
  return response
}

/**
 * Updates an existing project.
 *
 * @param {string} token - The authorization token.
 * @param {Object} body - The body of the request containing updated project details.
 * @param {string} body.id - The project ID.
 * @param {string} body.name - The name of the project.
 * @param {string} body.goal - The goal of the project.
 * @param {string} body.status - The status of the project.
 * @param {string} body.designLanguage - The design language of the project.
 * @param {Array<string>} body.languages - The languages of the project.
 * @param {string} body.type - The type of the project.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function updateProject(token, user, unit, role, body) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.post(
    `/project-update?id=${body.id}`,
    {
      name: body.name,
      goal: body.goal,
      status: body.status,
      designLanguage: body.designLanguage,
      languages: body.languages,
      type: body.type,
    },
    config
  )
  return response
}

/**
 * Fetches the members of a specific project.
 *
 * @param {string} token - The authorization token.
 * @param {string} id - The project ID.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function projectMembers(token, user, unit, role, id) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(`/list-project-members?id=${id}`, config)
  return response
}

/**
 * Adds a member to a specific project.
 *
 * @param {string} token - The authorization token.
 * @param {string} id - The project ID.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @param {string} member_name - The name of the member to add.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function projectMemberAdd(
  token,
  user,
  unit,
  role,
  id,
  member_name
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(
    `/project-member-add?id=${id}&member=${member_name}`,
    config
  )
  return response
}

/**
 * Removes a member from a specific project.
 *
 * @param {string} token - The authorization token.
 * @param {string} id - The project ID.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @param {string} member_name - The name of the member to remove.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function projectMemberRemove(
  token,
  user,
  unit,
  role,
  id,
  member_name
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.delete(
    `/project-member-remove?id=${id}&member=${member_name}`,
    config
  )
  return response
}

/**
 * Archives a specific project.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @param {string} id - The project ID.
 * @param {string} owner - The owner of the project.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function archiveProject(token, user, unit, role, id, owner) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(
    `/project-archive?id=${id}&hint_owner=${owner}`,
    config
  )
  return response
}
/**
 * Unarchives a specific project.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @param {string} id - The project ID.
 * @param {string} owner - The owner of the project.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function unarchiveProject(token, user, unit, role, id, owner) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(
    `/project-unarchive?id=${id}&hint_owner=${owner}`,
    config
  )
  return response
}

/**
 * Fetches the list of available archived projects.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function availableArchivedProjects(
  token,
  user,
  unit,
  role
  //filter TODO Add filter
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(
    `/available-archived-projects?metadata=true`,
    config
  )
  return response
}

/**
 * Copies an existing project.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @param {string} id - The project ID.
 * @param {string} owner - The owner of the project.
 * @param {string} copy - The fields to copy (e.g., metadata, qnr, data, docs, queries).
 * @param {string} name - The new name for the copied project, if empty will append -copy to original project name.
 * @param {string} newOwner - The owner of the copied project.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function copyProject(
  token,
  user,
  unit,
  role,
  id,
  owner,
  copy,
  name,
  newOwner
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(
    `/project-copy?id=${id}&hint_owner=${owner}&copy=${copy}&name=${name}&owner=${newOwner}`,
    config
  )
  return response
}

/**
 * Moves a project to a new owner.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @param {string} id - The project ID.
 * @param {string} owner - The current owner of the project.
 * @param {string} newOwner - The new owner of the project.
 * @returns {Promise<Object>} The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function moveProject(
  token,
  user,
  unit,
  role,
  id,
  owner,
  newOwner
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(
    `/project-move?id=${id}&hint_owner=${owner}&owner=${newOwner}`,
    config
  )
  return response
}
