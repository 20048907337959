import React, { useState, useEffect, useRef, useMemo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip, Tree, Input } from "antd"
import { faFolder, faRefresh } from "@fortawesome/free-solid-svg-icons"
import { DownOutlined } from "@ant-design/icons"
import { Loader } from "components/UI/Loader/Loader"

const { Search } = Input

/**
 * Transforms the codebook data into a tree structure suitable for the Ant Design Tree component.
 *
 * @param {Object|Array} data - The codebook data to transform.
 * @param {string} [parentKey=""] - The key of the parent node, used for generating unique keys.
 * @returns {Array} An array of tree nodes with the required structure for Ant Design Tree.
 */
const transformCodebookToTreeData = (data, parentKey = "") => {
  if (Array.isArray(data)) {
    return data.map((entry, index) => {
      const { label, type, value } = entry
      const currentKey = parentKey ? `${parentKey}-${index}` : `${index}`
      return {
        title: label,
        key: currentKey,
        icon: <FontAwesomeIcon icon={faFolder} color="#36c3ed" />,
        children:
          type === "TABLE"
            ? transformCodebookToTreeData(value, currentKey)
            : undefined,
        isLeaf: type !== "TABLE",
      }
    })
  } else if (typeof data === "object" && data !== null) {
    return Object.entries(data).map(([key, value], index) => {
      const currentKey = parentKey ? `${parentKey}-${index}` : `${index}`
      return {
        title: key,
        key: currentKey,
        icon: <FontAwesomeIcon icon={faFolder} color="#36c3ed" />,
        children: transformCodebookToTreeData(value, currentKey),
        isLeaf: typeof value !== "object" || value === null,
      }
    })
  }
  return []
}

/**
 * DataTree component displays a hierarchical view of the codebook data.
 * It supports searching, dynamic tree height adjustments, and inserting selected nodes into an editor.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.editor - The editor instance to insert content into.
 * @param {Object|Array} props.codebook - The codebook data to display in the tree.
 * @param {Function} props.regenerateCodebook - Function to regenerate the codebook.
 * @param {boolean} props.isCodebookLoading - Indicates if the codebook is currently being loaded or regenerated.
 */
const DataTree = ({
  editor,
  codebook,
  regenerateCodebook,
  isCodebookLoading,
}) => {
  const [treeData, setTreeData] = useState([])
  const [treeHeight, setTreeHeight] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")
  const treeContainerRef = useRef(null)

  useEffect(() => {
    if (codebook) {
      setTreeData(transformCodebookToTreeData(codebook))
    }
  }, [codebook])

  useEffect(() => {
    const updateTreeHeight = () => {
      if (treeContainerRef.current) {
        const containerHeight = treeContainerRef.current.clientHeight
        setTreeHeight(containerHeight)
      }
    }

    updateTreeHeight()
    window.addEventListener("resize", updateTreeHeight)

    return () => window.removeEventListener("resize", updateTreeHeight)
  }, [])

  /**
   * Retrieves the full path of a node given its key.
   *
   * @param {string} key - The key of the node.
   * @returns {string[]} An array representing the path from root to the node.
   */
  const getNodePath = (key) => {
    const keys = key.split("-")
    const path = []

    const traverse = (nodes, depth) => {
      for (const node of nodes) {
        if (node.key === keys.slice(0, depth + 1).join("-")) {
          path.push(node.title)
          if (depth === keys.length - 1) {
            return true
          }
          if (node.children) {
            return traverse(node.children, depth + 1)
          }
        }
      }
      return false
    }

    traverse(treeData, 0)
    return path
  }

  /**
   * Handles the selection of a node in the tree.
   * Inserts the full path of the selected node into the editor.
   *
   * @param {string[]} selectedKeys - The keys of the selected nodes.
   * @param {Object} info - Information about the selection event.
   */
  const onSelect = (selectedKeys, info) => {
    const { node } = info
    const { key } = node

    const path = getNodePath(key)
    const insertContent =
      path.length > 1 ? `<<${path.join("//")}>> ` : `<<${path[0]}>> `
    editor.commands.insertContent(insertContent)
  }

  /**
   * Filters the tree data based on the search term.
   *
   * @type {Array}
   */
  const filteredTreeData = useMemo(() => {
    if (!searchTerm) return treeData

    const filterNode = (node) => {
      if (node.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true
      }
      if (node.children) {
        const filteredChildren = node.children.filter(filterNode)
        if (filteredChildren.length > 0) {
          return { ...node, children: filteredChildren }
        }
      }
      return false
    }

    return treeData.filter(filterNode)
  }, [treeData, searchTerm])

  /**
   * Handles changes in the search input.
   *
   * @param {string} value - The current value of the search input.
   */
  const handleSearch = (value) => {
    setSearchTerm(value)
  }

  if (isCodebookLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Loader fontSize={48} color="#36C3ED" />
        <span>Regenerating Data Tree...</span>
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex w-full mb-2 shadow-sm p-2">
        <Search
          placeholder="Search Data Tree"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 250, marginRight: 8 }}
        />
        <Tooltip title="Regenerate Data Tree">
          <FontAwesomeIcon
            icon={faRefresh}
            onClick={regenerateCodebook}
            className="ml-auto cursor-pointer w-4 h-4 my-auto"
            color="#36C3ED"
          />
        </Tooltip>
      </div>
      <div ref={treeContainerRef} className="flex-grow overflow-hidden">
        {treeHeight > 0 && (
          <Tree
            showIcon
            virtual
            height={treeHeight}
            treeData={filteredTreeData}
            onSelect={onSelect}
            switcherIcon={<DownOutlined />}
          />
        )}
      </div>
    </div>
  )
}

export default DataTree
