/**
 * Truncates the project name if it exceeds the specified maximum length.
 *
 * This function checks if the provided name is longer than the specified maximum length.
 * If it is, the function truncates the name and appends an ellipsis ("...") to indicate
 * that the name has been shortened.
 *
 * @function
 * @param {string} name - The project name to be truncated.
 * @param {number} maxLength - The maximum length allowed for the project name before truncation.
 * @returns {string} - The truncated project name with an ellipsis if it exceeds the max length, otherwise the original name.
 */
export const truncateName = (name, maxLength) => {
  return name.length > maxLength ? name.substring(0, maxLength) + "..." : name
}
