/**
 * Login route component.
 * @module Login
 *
 *
 */
import React from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"

import LoginContainer from "containers/Login/Login.container"

/**
 * Renders the Login route component.
 * @returns {React.ReactNode} The rendered Login component.
 */
const Login = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Data Q - Login</title>
        </Helmet>
        <LoginContainer />
      </HelmetProvider>
    </>
  )
}

export default Login
