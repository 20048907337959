/**
 * @module CopyProjectModal
 * @description A modal component for copying a project to a new owner with selected parts.
 */

import React, { useEffect, useState } from "react"
import { Modal, Input, Checkbox, Divider } from "antd"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { useProjects } from "hooks/useProjects"
import { useUnits } from "hooks/useUnits"
import DataQButton from "components/UI/Buttons/DataQButton"
import {
  SuccessNotification,
  ErrorNotification,
} from "components/UI/Notifications/NotificationTemplate.component"
import { projectAtom } from "atoms/projectAtom"
import handleSequenceError from "utils/handleSequenceError"

/**
 * @function CopyProjectModal
 * @description A modal component for copying a project to a new owner with selected parts.
 * @returns {JSX.Element} The CopyProjectModal component
 */
const CopyProjectModal = () => {
  const project = useRecoilValue(projectAtom)
  const { copyUserProject, isCopyingProject } = useProjects()
  const { getUnitUsers } = useUnits()
  const navigate = useNavigate()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [userData, setUserData] = useState([])
  const [newProjectName, setNewProjectName] = useState("")
  const [selectedOwner, setSelectedOwner] = useState("")
  const [copyOptions, setCopyOptions] = useState({
    metadata: true,
    qnr: true,
    data: true,
    docs: true,
    queries: true,
  })

  const copyOptionLabels = {
    metadata: "Project Info",
    qnr: "Questionnaire",
    data: "Data",
    docs: "Documents",
    queries: "Queries",
  }

  useEffect(() => {
    if (project.id) {
      getUserData()
    }
  }, [project.id])

  /**
   * @async
   * @function getUserData
   * @description Fetches user data for the unit and sets it in the state.
   * @returns {Promise<void>}
   */
  const getUserData = async () => {
    try {
      const res = await getUnitUsers()
      let userArray = res.data.split("\n")
      userArray = [
        project.Created_by,
        ...userArray.filter(
          (user) => user.toLowerCase() !== project.Created_by.toLowerCase()
        ),
      ]
      setUserData(userArray || [])
    } catch (error) {
      console.log("Error Fetching Data:", error.message)
    }
  }

  /**
   * @async
   * @function copyProject
   * @description Copies the project with selected options to a new owner.
   * @returns {Promise<void>}
   */
  const copyProject = async () => {
    if (!newProjectName || !selectedOwner) {
      ErrorNotification(
        "Error Copying Project!",
        "Please enter a project name and select a new owner."
      )
      return
    }

    const copyParts = Object.entries(copyOptions)
      .filter(([, value]) => value)
      .map(([key]) => key)
      .join(",")

    try {
      const res = await copyUserProject(
        project.id,
        project.Created_by,
        copyParts,
        newProjectName,
        selectedOwner
      )
      if (handleSequenceError(res.data, "Error Copying Project!")) {
        SuccessNotification(
          "Project Copied Successfully!",
          "The project has been copied successfully."
        )
        navigate("/home")
      }
    } catch (error) {
      ErrorNotification("Error Copying Project!", error.message)
    }
  }

  /**
   * @function showModal
   * @description Sets the modal visibility to true.
   */
  const showModal = () => {
    setIsModalVisible(true)
  }

  /**
   * @function cancel
   * @description Resets the modal state and closes it.
   */
  const cancel = () => {
    setIsModalVisible(false)
    setNewProjectName("")
    setSelectedOwner("")
    setCopyOptions({
      metadata: true,
      qnr: true,
      data: true,
      docs: true,
      queries: true,
    })
  }

  /**
   * @function handleCopyOptionChange
   * @description Handles changes in the copy options checkboxes.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event
   */
  const handleCopyOptionChange = (e) => {
    setCopyOptions({
      ...copyOptions,
      [e.target.name]: e.target.checked,
    })
  }

  /**
   * @function handleOwnerChange
   * @description Handles changes in the owner selection.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event
   */
  const handleOwnerChange = (e) => {
    setSelectedOwner(e.target.checked ? e.target.value : "")
  }

  if (!userData) return null
  return (
    <>
      <DataQButton onClick={showModal} type="primary" className="text-black">
        Copy Project
        <FontAwesomeIcon className="ml-2" icon={faCopy} />
      </DataQButton>
      <Modal
        open={isModalVisible}
        centered={true}
        footer={false}
        closable={false}
        destroyOnClose={true}
        width={600}
      >
        <h2 className="text-xl font-semibold m-0">Copy Project</h2>
        <Input
          placeholder="Enter new project name"
          value={newProjectName}
          onChange={(e) => setNewProjectName(e.target.value)}
          className="my-4"
        />

        <h3 className="text-lg font-medium m-0 mt-2">Select New Owner</h3>
        <div className="max-h-40 overflow-y-auto">
          {userData.map((user) => (
            <div key={user} className="flex items-center mb-2">
              <Checkbox
                value={user}
                checked={selectedOwner === user}
                onChange={handleOwnerChange}
                className="mr-2"
              />
              <span>{user}</span>
            </div>
          ))}
        </div>
        <Divider />
        <h3 className="text-lg font-medium mt-0 mb-2">Select Parts to Copy</h3>
        <div className="flex flex-wrap">
          {Object.entries(copyOptions).map(([key, value]) => (
            <div key={key} className="w-1/2 mb-2 flex items-center">
              <Checkbox
                name={key}
                checked={value}
                onChange={handleCopyOptionChange}
                className="mr-2"
              />
              <span>{copyOptionLabels[key]}</span>
            </div>
          ))}
        </div>
        <div className="flex flex-row mt-5 justify-between">
          <DataQButton type="secondary" onClick={cancel}>
            Cancel
          </DataQButton>
          <DataQButton
            type="primary"
            onClick={copyProject}
            loading={isCopyingProject}
          >
            Copy Project
          </DataQButton>
        </div>
      </Modal>
    </>
  )
}

export default CopyProjectModal
