import React from "react"
import { Form, Input, Select } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLanguage } from "@fortawesome/free-solid-svg-icons"
import DataQButton from "components/UI/Buttons/DataQButton"
import { ErrorNotification } from "components/UI/Notifications/NotificationTemplate.component"

/**
 * A form component for creating a new variant.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.project - The project data.
 * @param {function} props.createVariantCallback - Callback function to execute after variant creation.
 * @param {function} props.setIsModalVisible - Function to set the modal visibility state.
 * @param {function} props.insertVariantText - Function to insert text into the editor.
 * @param {Array} props.variants - The list of existing variants.
 * @param {Function} props.setVariants - Function to set the list of variants.
 * @param {Function} props.setSelectedVariant - Function to set the selected variant.
 * @returns {JSX.Element} The rendered component.
 */
const CreateVariantForm = (props) => {
  const {
    project,
    createVariantCallback,
    setIsModalVisible,
    insertVariantText,
    variants,
    setVariants,
    setSelectedVariant,
  } = props
  const [form] = Form.useForm()

  /**
   * Submits the form and creates a new project variant.
   *
   * @async
   * @param {Object} variantFormValues - The values from the form fields.
   * @returns {Promise<void>} A promise that resolves when the variant is created.
   */

  const submitVariantForm = async (variantFormValues) => {
    try {
      const defineVariantLine = `#DEFINE VARIANT ${variantFormValues.name}`
      const languagesLine = `#LANGUAGE ${variantFormValues.languages.join(
        ", "
      )}`
      if (
        variants !== undefined &&
        variants.some((variant) => variant.name === variantFormValues.name)
      ) {
        ErrorNotification(
          "Variant Already Exists",
          "A variant with this name already exists. Please choose a different name."
        )
      } else {
        // Ensure that the new variant block starts and ends with proper newlines
        const newVariantContent = `\n\n${defineVariantLine}\n${languagesLine}\n#LINE 0\n\n`
        insertVariantText(newVariantContent)
        const newVariant = {
          ...variantFormValues,
          content: "\n",
          languages: variantFormValues.languages,
        }
        const updatedVariants = [...variants, newVariant]
        setVariants(updatedVariants)
        setSelectedVariant(newVariant) // Switch to the newly created variant
        setIsModalVisible(false)
        createVariantCallback(updatedVariants)
      }
    } catch (error) {
      console.log("create variant error", error)
      throw error
    }
  }

  /**
   * Resets the form fields and hides the modal.
   *
   * @function
   */
  const hideModal = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  return (
    <>
      <Form
        form={form}
        name="create-project-form"
        onFinish={submitVariantForm}
        style={{ textAlign: "left" }}
        layout="vertical"
        initialValues={{
          languages:
            typeof project.Languages === "string"
              ? JSON.parse(project.Languages)
              : project.Languages,
        }}
      >
        <Form.Item
          style={{ marginBottom: "2em" }}
          name={"name"}
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: "Please Enter Variant Name",
            },
          ]}
        >
          <Input
            prefix={
              <>
                <FontAwesomeIcon
                  className="flex-shrink-0 h-5 mr-2"
                  icon={faLanguage}
                />
              </>
            }
            label="Variant Name"
            placeholder="Variant Name"
          />
        </Form.Item>
        <Form.Item
          name={"languages"}
          label="Languages"
          style={{ marginBottom: "2em" }}
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: "Please select at least one language for this variant",
            },
          ]}
        >
          <Select
            options={project.Languages.map((language) => ({
              label: language,
              value: language,
            }))}
            mode="tags"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>

        <div className="flex flex-row mt-5">
          <div className="ml-auto mr-2 space-x-2">
            <DataQButton type="primary" htmlType="submit">
              Create Variant
            </DataQButton>
            <DataQButton onClick={hideModal} type="secondary">
              Cancel
            </DataQButton>
          </div>
        </div>
      </Form>
    </>
  )
}

export default CreateVariantForm
