import React from "react"
import { Empty } from "antd"

const InvalidData = () => {
  return (
    <div className="flex flex-col rounded-br-xl h-full text-2xl font-semibold p-2">
      <Empty
        className="mx-auto my-auto"
        description={
          "No Data File Found! Please go to Data Import tab and import/generate the data file"
        }
      />
    </div>
  )
}

export default InvalidData
