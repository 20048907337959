import axios from "../request"
import JSZip from "jszip"
import { fileToBase64 } from "utils/fileToBase64"

/**
 * Uploads data for data reporting.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the requester.
 * @param {string} id - The document ID.
 * @param {string} owner - The owner of the project.
 * @param {string} type - The type of the file to be uploaded. (csv, map or json)
 * @param {File} file - The file object to be uploaded.
 * @returns {Promise<Object>} - The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function datafileUpload(
  token,
  user,
  unit,
  role,
  id,
  owner,
  type,
  file
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }

  // Initialize JSZip instance
  const zip = new JSZip()

  // Add file content to zip
  zip.file(type, file, { compression: "DEFLATE" })

  const zippedContent = await zip.generateAsync({
    type: "blob",
    compression: "DEFLATE",
  })

  let zippedBase64 = await fileToBase64(zippedContent)

  const encoder = new TextEncoder()
  const encoded = encoder.encode(zippedBase64)

  const response = await axios.post(
    `/datafile-save?id=${id}&hint_owner=${owner}&type=${type}&encode=base64_zip`,
    encoded,
    config
  )
  return response
}

/**
 * Retrieves the list of uploaded data files for a given project
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the requester.
 * @param {string} id - The project ID.
 * @param {string} owner - The owner of the project.
 * @param {string} type - The type of the file to be retrieved. (csv, map or json)
 * @param {string} size - optional, write 'size' to get the size of the file as the response (for checking if the file exists)
 * @returns {Promise<Object>} - The response from the API containing the list of uploaded data files.
 * @throws Will throw an error if the request fails.
 */
export async function datafileGet(
  token,
  user,
  unit,
  role,
  id,
  owner,
  type,
  size
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }

  const response = await axios.get(
    `/datafile-get?id=${id}&hint_owner=${owner}&type=${type}&encode=base64&${size}`,
    config
  )
  return response
}

/**
 * Deletes an uploaded data file for a given projectt ID.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the requester.
 * @param {string} id - The project ID.
 * @param {string} owner - The owner of the project.
 * @param {string} type - The type of the file to be deleted. (csv, map or json)
 * @returns {Promise<Object>} - The response from the API confirming the deletion.
 * @throws Will throw an error if the request fails.
 */
export async function datafileDelete(token, user, unit, role, id, owner, type) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }

  const response = await axios.delete(
    `/datafile-delete?id=${id}&hint_owner=${owner}&type=${type}`,
    config
  )
  return response
}

/**
 * Combines uploaded data with a data map for a given project ID.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the requester.
 * @param {string} id - The project ID.
 * @returns {Promise<Object>} - The response from the API confirming the combination.
 * @throws Will throw an error if the request fails.
 */
export async function datafileCombine(token, user, unit, role, id) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }

  const response = await axios.get(`/datafile-combine?id=${id}`, config)
  return response
}
