import React from "react"
import { Form, Input } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolder } from "@fortawesome/free-solid-svg-icons"
import DataQButton from "components/UI/Buttons/DataQButton"
import { useProjects } from "hooks/useProjects"

/**
 * CreateProjectForm component renders a form for creating a new project.
 *
 * @component
 * @example
 * const createProjectCallback = (project) => {
 *   console.log(project);
 * };
 * const setIsModalVisible = (isVisible) => {
 *   // logic to show/hide modal
 * };
 * return (
 *   <CreateProjectForm
 *     createProjectCallback={createProjectCallback}
 *     setIsModalVisible={setIsModalVisible}
 *   />
 * );
 *
 * @param {Object} props - Component properties
 * @param {function} props.createProjectCallback - Callback function to handle project creation response
 * @param {function} props.setIsModalVisible - Function to show/hide the modal
 * @returns {JSX.Element} The CreateProjectForm component
 */
const CreateProjectForm = ({ createProjectCallback, setIsModalVisible }) => {
  const [form] = Form.useForm()
  const { createUserProject, isCreatingProject } = useProjects()

  /**
   * Submits the project form and handles the response.
   *
   * @async
   * @function
   * @param {Object} projectFormValues - The values from the project form
   * @returns {Promise<void>}
   */
  const submitProjectForm = async (projectFormValues) => {
    try {
      const res = await createUserProject(projectFormValues)
      createProjectCallback(res.data)
      hideModal()
    } catch (error) {
      console.error("Error creating project:", error)
    }
  }

  /**
   * Resets the form fields and hides the modal.
   *
   * @function
   */
  const hideModal = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  return (
    <Form
      form={form}
      name="create-project-form"
      onFinish={submitProjectForm}
      style={{ textAlign: "left" }}
      layout="vertical"
    >
      <Form.Item
        style={{ marginBottom: "2em" }}
        name="name"
        validateTrigger="onSubmit"
        rules={[
          {
            required: true,
            message: "Please Enter a Name",
          },
        ]}
      >
        <Input
          prefix={
            <FontAwesomeIcon
              className="flex-shrink-0 h-5 mr-2"
              icon={faFolder}
            />
          }
          placeholder="Project Name"
        />
      </Form.Item>

      <Form.Item
        style={{ marginBottom: "1em" }}
        name="goal"
        validateTrigger="onSubmit"
        rules={[
          {
            required: false,
            message: "Please enter a project description",
          },
        ]}
      >
        <Input.TextArea
          placeholder="Enter Project Description..."
          style={{
            resize: "none",
            borderRadius: "8px",
            height: "100px",
            fontWeight: "500",
            fontSize: "16px",
          }}
        />
      </Form.Item>

      <div className="flex flex-row mt-5">
        <div className="ml-auto mr-2 space-x-2">
          <DataQButton
            type="primary"
            htmlType="submit"
            isLoading={isCreatingProject}
          >
            Create Project
          </DataQButton>
          <DataQButton onClick={hideModal} type="secondary">
            Cancel
          </DataQButton>
        </div>
      </div>
    </Form>
  )
}

export default CreateProjectForm
