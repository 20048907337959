import React, { useState } from "react"
import { Modal, Typography } from "antd"

import DataQButton from "components/UI/Buttons/DataQButton"
import CreateProjectForm from "./CreateProjectForm.component"

import { SuccessNotification } from "components/UI/Notifications/NotificationTemplate.component"
import handleSequenceError from "utils/handleSequenceError"

/**
 * AddProjectModal component - Displays a modal for creating a new project.
 *
 * This component renders a button that, when clicked, shows a modal containing
 * a form for creating a new project. The modal can be closed upon successfully
 * creating a project or by other interactions defined within the modal.
 *
 * @component
 * @param {Object} props - The component props.
 * @returns {JSX.Element} The AddProjectModal component.
 */
const AddProjectModal = (props) => {
  const { refresh } = props
  const { Title } = Typography
  const [isModalVisible, setIsModalVisible] = useState(false)

  /**
   * Handles actions after successfully creating a project.
   * It closes the modal and optionally triggers additional actions such as refreshing the project list.
   *
   * @function createProjectCallback
   * @param {Object} res - The response object from the project creation request.
   */
  const createProjectCallback = (res) => {
    if (handleSequenceError(res, "Error creating Project!")) {
      setIsModalVisible(false)
      SuccessNotification(
        "Project Created Successfully!",
        "The project has been created successfully."
      )
      refresh()
    }
  }

  /**
   * Shows the modal by setting the modal visibility state to true.
   */
  const showModal = () => {
    setIsModalVisible(true)
  }

  return (
    <>
      <DataQButton onClick={showModal} type="secondary" classname="text-black">
        Create Project
      </DataQButton>
      <Modal
        open={isModalVisible}
        centered={true}
        footer={false}
        closable={false}
        destroyOnClose={true}
      >
        <Title style={{ marginTop: "0.5em" }} level={4}>
          Create Project
        </Title>
        <CreateProjectForm
          setIsModalVisible={setIsModalVisible}
          createProjectCallback={createProjectCallback}
        />
      </Modal>
    </>
  )
}

export default AddProjectModal
