import React from "react"
import { useState } from "react"
import { useKindeAuth } from "@kinde-oss/kinde-auth-react"
import { Switch, Tooltip } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArchive, faFolder } from "@fortawesome/free-solid-svg-icons"

import ProjectTable from "./ProjectTable/ProjectTable"
import AddProjectModal from "./CreateProject/AddProjectModal.component"
import DataQButton from "components/UI/Buttons/DataQButton"

/**
 * ProjectDisplay component responsible for rendering and managing the display of active and archived projects.
 * It allows the user to switch between viewing owned and member projects, as well as archived or active projects.
 * The component also provides functionalities to unarchive or archive projects and add new ones.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.filter - The current filter applied to the projects ("owned", "member", "all").
 * @param {Function} props.setFilter - The function to change the project filter.
 * @param {Function} props.getProjects - Function to fetch active projects.
 * @param {Function} props.getArchivedProjects - Function to fetch archived projects.
 * @param {boolean} props.isLoading - Whether the active projects are currently being loaded.
 * @param {boolean} props.isLoadingArchived - Whether the archived projects are currently being loaded.
 * @param {Array<Object>} props.projects - The list of active projects to display.
 * @param {Array<Object>} props.archivedProjects - The list of archived projects to display.
 * @param {Function} props.handleUnarchive - Function to unarchive a project.
 * @param {Function} props.handleArchive - Function to archive a project.
 * @returns {React.ReactNode} The ProjectDisplay component rendered as a React node.
 */
const ProjectDisplay = (props) => {
  const {
    filter,
    setFilter,
    getProjects,
    getArchivedProjects,
    isLoading,
    isLoadingArchived,
    projects,
    archivedProjects,
    handleUnarchive,
    handleArchive,
  } = props
  const [showArchived, setShowArchived] = useState(false)
  const { getClaim } = useKindeAuth()
  const userRoles = getClaim("roles")
  const isAdmin =
    userRoles?.value?.some(
      (role) => role.key === "admin" || role.key === "super-admin"
    ) || false

  /**
   * Toggles the project filter between "owned" and "member".
   * @function toggleFilter
   */
  const toggleFilter = () => {
    setFilter(filter === "owned" ? "member" : "owned")
  }

  /**
   * Sets the project filter to "all", allowing viewing of all projects.
   * @function viewAllProjects
   */
  const viewAllProjects = () => {
    setFilter("all")
  }

  /**
   * Handles the switch between archived and active projects.
   * Fetches archived or active projects based on the switch state.
   * @function handleSwitchChange
   * @param {boolean} checked - The state of the switch (true for archived, false for active).
   */
  const handleSwitchChange = (checked) => {
    setShowArchived(checked)
    if (checked) {
      getArchivedProjects()
    } else {
      getProjects()
    }
  }

  // Sort the projects by creation date in descending order
  const sortedProjects = [...(showArchived ? archivedProjects : projects)].sort(
    (a, b) => new Date(b.metadata.Created_at) - new Date(a.metadata.Created_at)
  )

  return (
    <div className="flex flex-col p-5">
      <div className="flex flex-row items-center justify-between mb-2">
        <div className="flex items-center">
          <h1 className="text-3xl font-semibold m-0 mr-4">
            {showArchived
              ? "Archived Projects"
              : `Projects - ${
                  filter.charAt(0).toUpperCase() + filter.slice(1)
                }`}
          </h1>
          <Tooltip
            title={
              showArchived
                ? "Switch to active projects"
                : "Switch to archived projects"
            }
            placement="right"
          >
            <Switch
              checked={showArchived}
              onChange={handleSwitchChange}
              checkedChildren={<FontAwesomeIcon icon={faArchive} />}
              unCheckedChildren={<FontAwesomeIcon icon={faFolder} />}
              style={{ backgroundColor: "#36c3ed" }}
              className="mt-1"
            />
          </Tooltip>
        </div>
        <div className="flex items-center space-x-2 my-4 h-5">
          {!showArchived && (
            <>
              <DataQButton type="primary" onClick={toggleFilter}>
                {filter === "owned"
                  ? "View Member Projects"
                  : "View Owned Projects"}
              </DataQButton>
              {isAdmin && (
                <DataQButton type="secondary" onClick={viewAllProjects}>
                  View All Projects
                </DataQButton>
              )}
              <AddProjectModal refresh={getProjects} />
            </>
          )}
        </div>
      </div>

      <ProjectTable
        isLoading={showArchived ? isLoadingArchived : isLoading}
        projects={sortedProjects}
        isArchived={showArchived}
        handleUnarchive={handleUnarchive}
        handleArchive={handleArchive}
      />
    </div>
  )
}

export default ProjectDisplay
