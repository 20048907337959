import React, { useEffect, useRef } from "react"
import { EditorContent } from "@tiptap/react"
import Output from "./Output.component"
import Split from "react-split"

/**
 * Console component for writing and running scripts.
 *
 * This component provides a split view with an editor for writing scripts
 * and an output area for displaying results. It includes virtualized line numbers
 * for the editor and allows resizing of the editor and output areas.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.editor - The Tiptap editor instance.
 * @param {Object} props.result - The result of the script execution.
 * @param {boolean} props.scriptInProgress - Indicates whether a script is currently running.
 * @param {boolean} props.asyncScriptInProgress - Indicates whether an async script is currently running.
 * @returns {JSX.Element} The rendered Console component.
 */
const Console = ({
  editor,
  result,
  scriptInProgress,
  asyncScriptInProgress,
}) => {
  const editorWrapperRef = useRef(null)
  const lineNumbersRef = useRef(null)

  /**
   * Updates the line numbers in the editor.
   * This effect runs when the editor instance changes or updates.
   */
  useEffect(() => {
    if (!editor) return

    const lineNumbersContainer = lineNumbersRef.current
    if (!lineNumbersContainer) return

    const editorScrollContainer = editorWrapperRef.current
    if (!editorScrollContainer) return

    // Function to render virtualized line numbers
    const renderLineNumbers = () => {
      const { scrollTop, clientHeight } = editorScrollContainer
      const scrollBottom = scrollTop + clientHeight

      const lines = editor.view.dom.querySelectorAll(".ProseMirror > *")
      const lineHeights = []
      let totalHeight = 0

      // Calculate cumulative heights to determine line positions
      lines.forEach((line) => {
        const height = line.offsetHeight
        lineHeights.push({ height, top: totalHeight })
        totalHeight += height
      })

      // Find the visible lines
      const visibleLines = []
      for (let i = 0; i < lineHeights.length; i++) {
        const { top, height } = lineHeights[i]
        if (top + height >= scrollTop && top <= scrollBottom) {
          visibleLines.push({ index: i, top, height })
        }
        if (top > scrollBottom) {
          break
        }
      }

      // Clear existing line numbers
      lineNumbersContainer.innerHTML = ""

      // Render visible line numbers
      visibleLines.forEach(({ index, top, height }) => {
        const lineNumber = document.createElement("div")
        lineNumber.className = "line-number"
        lineNumber.textContent = index + 1
        lineNumber.style.top = `${top}px`
        lineNumber.style.height = `${height}px`
        lineNumbersContainer.appendChild(lineNumber)
      })
    }

    // Initial render
    renderLineNumbers()

    // Scroll event listener with throttling
    let scrollTimeout = null
    const handleScroll = () => {
      if (scrollTimeout) return
      scrollTimeout = setTimeout(() => {
        renderLineNumbers()
        scrollTimeout = null
      }, 50) // Adjust the throttle delay as needed
    }

    editorScrollContainer.addEventListener("scroll", handleScroll)

    // Update line numbers on editor updates
    editor.on("update", renderLineNumbers)

    // Clean up
    return () => {
      editorScrollContainer.removeEventListener("scroll", handleScroll)
      editor.off("update", renderLineNumbers)
    }
  }, [editor])

  return (
    <Split
      direction="vertical"
      sizes={[50, 50]}
      minSize={100}
      expandToMin={false}
      gutterSize={10}
      gutterAlign="center"
      snapOffset={30}
      dragInterval={1}
      className="split-container h-full flex flex-col"
    >
      <div className="flex flex-col min-h-0 relative">
        <div
          ref={editorWrapperRef}
          className="flex relative overflow-auto flex-grow"
        >
          {/*
            Line Numbers Container
            - Positioned absolutely to align with the editor content
          */}
          <div
            ref={lineNumbersRef}
            className="line-numbers border-gray-300"
            style={{ position: "absolute", left: 0, top: 0 }}
          ></div>
          <div className="w-full">
            <EditorContent editor={editor} className="editor-content w-full" />
          </div>
        </div>
      </div>
      <Output
        result={result}
        scriptInProgress={scriptInProgress}
        asyncScriptInProgress={asyncScriptInProgress}
      />
    </Split>
  )
}

export default Console
