import React from "react"
import DataQButton from "components/UI/Buttons/DataQButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArchive, faRotateLeft } from "@fortawesome/free-solid-svg-icons"

const getColumns = (isArchived, handleUnarchive, handleArchive, user) => [
  {
    title: "Project Name",
    dataIndex: "metadata",
    key: 1,
    render: (metadata) => metadata.Name,
    sorter: (a, b) => a.metadata.Name.localeCompare(b.metadata.Name),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Type",
    dataIndex: "metadata",
    key: 2,
    render: (metadata) => metadata.Type,
  },
  {
    title: "Project Owner",
    dataIndex: "owner",
    key: 3,
  },
  {
    title: "Date Created",
    dataIndex: "metadata",
    key: 4,
    render: (metadata) => metadata.Created_at,
    sorter: (a, b) =>
      new Date(a.metadata.Created_at) - new Date(b.metadata.Created_at),
    sortDirections: ["ascend"],
  },
  {
    title: "Status",
    dataIndex: "metadata",
    key: 5,
    render: (metadata) => metadata.Status,
  },

  {
    title: "Actions",
    key: 6,
    render: (_, record) => (
      <>
        {user.username.toLowerCase() === record.owner.toLowerCase() && (
          <DataQButton
            type="primary"
            onClick={(e) => {
              e.stopPropagation()

              isArchived
                ? handleUnarchive(record.id, record.owner)
                : handleArchive(record.id, record.owner)
            }}
          >
            {isArchived ? "Unarchive" : "Archive"}{" "}
            <FontAwesomeIcon
              className="ml-2"
              icon={isArchived ? faRotateLeft : faArchive}
            />
          </DataQButton>
        )}
      </>
    ),
  },
]

export default getColumns
