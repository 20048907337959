import React from "react"
import { Modal } from "antd"
import DataQButton from "../Buttons/DataQButton"

/**
 * ConfirmationModal component displays a modal dialog with customizable
 * title, cancel, and confirm buttons. The modal remains open until
 * either the cancel or confirm action is triggered.
 *
 * @component
 * @param {Object} props - The props object for this component.
 * @param {string} props.title - The title displayed at the top of the modal.
 * @param {boolean} props.isVisible - A boolean value indicating whether the modal is visible.
 * @param {Function} props.cancel - Callback function to be executed when the cancel button is clicked.
 * @param {Function} props.confirm - Callback function to be executed when the confirm button is clicked.
 * @param {string} [props.confirmButtonText="Confirm"] - Text displayed on the confirm button. Defaults to "Confirm" if not provided.
 * @param {string} [props.confirmButtonType="secondary"] - Type of the confirm button, determining its styling. Defaults to "secondary" if not provided.
 * @param {string} [props.message] - Message displayed in the body of the modal.

 * @returns {JSX.Element} The rendered ConfirmationModal component.
 *
 * @example
 * <ConfirmationModal
 *   title="Confirm Action"
 *   isVisible={true}
 *   cancel={() => console.log('Cancelled')}
 *   confirm={() => console.log('Confirmed')}
 *   confirmButtonText="Yes, Confirm"
 *   confirmButtonType="primary"
 *   message="Are you sure you want to confirm this action?"
 * />
 */
const ConfirmationModal = (props) => {
  const {
    title,
    isVisible,
    cancel,
    confirm,
    confirmButtonText,
    confirmButtonType,
    message,
  } = props
  return (
    <Modal
      centered
      title={title}
      open={isVisible}
      closable={false}
      footer={null}
    >
      <div className="flex flex-col mb-2">
        <p className="text-md">{message}</p>
      </div>
      <div className="flex flex-row space-x-2">
        <DataQButton
          className="ml-auto"
          type="primary"
          onClick={() => cancel(false)}
        >
          Cancel
        </DataQButton>
        <DataQButton type={confirmButtonType || "secondary"} onClick={confirm}>
          {confirmButtonText || "Confirm"}
        </DataQButton>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
