/**
 * Home route component.
 * It renders the Main template component wrapping the Home container component.
 * @module Home
 */

import React from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"

import HomeContainer from "containers/Home/Home.container"

/**
 * Renders the Home route component.
 * This component is responsible for fetching the user's projects and displaying them.
 * @function Home
 * @returns {React.ReactNode} The rendered Home component.
 */
const Home = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Data Q - Projects</title>
        </Helmet>
      </HelmetProvider>
      <HomeContainer />
    </>
  )
}

export default Home
