//CSS classes syntax highlighting of keywords
const keywordStyles = {
  EXCEPTIONS: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  DATA: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  "INSERT AT": {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  "REDEFINE LIST": {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  "FOR EACH": {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  TERMINATE: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  "SKIP TO": {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  IF: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  "IF SELECTED": {
    keywordClass: "highlight-as",
    contentClass: "",
  },
  SELECTED: {
    keywordClass: "highlight-if-selected",
    contentClass: "highlight-item",
  },

  BEGIN: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  END: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  "FILL IN TEXT": {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  "FILL IN NUMBER": {
    keywordClass: "highlight-bold",
    contentClass: "highlight-note",
  },
  "FILL IN REAL": {
    keywordClass: "highlight-bold",
    contentClass: "highlight-note",
  },
  HIDE: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  HIDDEN: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  VALIDATE: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-validate",
  },
  "SELECT FROM": {
    keywordClass: "highlight-bold",
    contentClass: "",
  },
  "SELECT ONE FROM": {
    keywordClass: "highlight-bold",
    contentClass: "",
  },
  "SELECT MULTIPLE FROM": {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  "SELECT MANY FROM": {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  PER: {
    keywordClass: "highlight-bold",
    contentClass: "",
  },
  "SELECT FROM RANGE": {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  QUESTION: {
    keywordClass: "highlight-bold",
    contentClass: "",
  },
  Q: {
    keywordClass: "highlight-bold",
    contentClass: "",
  },
  "RANGE LIST": {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  EXTEND: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  CHANGE: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  REMOVE: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-note",
  },
  "ADD LIST": {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  TRIM: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  HEADER: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  SECTION: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item ",
  },
  SURVEY: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  "INSERT AFTER": {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  NOTE: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-note",
  },
  LIST: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  TEXT: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-normal",
  },
  LOCATION: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  RANDOMIZE: {
    keywordClass: "highlight-bold",
    contentClass: "highlight-item",
  },
  DEFINESPLIT: {
    keywordClass: "highlight-normal",
    contentClass: "highlight-item",
  },
  AS: {
    keywordClass: "highlight-as",
    contentClass: "",
  },

  "ANY OF": {
    keywordClass: "highlight-as",
    contentClass: "",
  },
  OR: {
    keywordClass: "highlight-as",
    contentClass: "",
  },
  AND: {
    keywordClass: "highlight-as",
    contentClass: "",
  },
  IS: {
    keywordClass: "highlight-as",
    contentClass: "",
  },
  "IS NOT": {
    keywordClass: "highlight-as",
    contentClass: "",
  },

  // Add more keywords as necessary with corresponding styles
}
export default keywordStyles
