import React from "react"
import { useRecoilValue } from "recoil"
import { Divider } from "antd"
import { Loader } from "components/UI/Loader/Loader"
import {
  faCircleCheck,
  faCircleExclamation,
  faClose,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { projectAtom } from "atoms/projectAtom"

/**
 * QuestionnaireCheckDisplay component displays the results of a questionnaire check,
 * including any errors or warnings found during the check process.
 *
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.checkQuestionnaireWarnings - The list of warnings found during the check.
 * @param {Array<Object>} props.checkQuestionnaireErrors - The list of errors found during the check.
 * @param {Function} props.setCheckingQuestionnaireEnabled - Function to enable or disable the questionnaire check display.
 * @param {Function} props.checkQuestionnaire - Function to trigger the questionnaire check.
 * @param {boolean} props.isCheckingQuestionnaire - Indicates if the questionnaire is currently being checked.
 * @param {Function} props.handleErrorClick - Function to handle the click event on an error message.
 * @returns {JSX.Element} The rendered QuestionnaireCheckDisplay component.
 */
const QuestionnaireCheckDisplay = ({
  checkQuestionnaireWarnings = [],
  checkQuestionnaireErrors = [],
  setCheckingQuestionnaireEnabled,
  checkQuestionnaire,
  isCheckingQuestionnaire,
  handleErrorClick,
}) => {
  const project = useRecoilValue(projectAtom)

  /**
   * Handles the refresh button click to re-check the questionnaire.
   *
   * @function handleRefreshClick
   */
  const handleRefreshClick = () => checkQuestionnaire(project.id)

  /**
   * Handles the close button click to hide the questionnaire check display.
   *
   * @function handleCloseClick
   */
  const handleCloseClick = () => setCheckingQuestionnaireEnabled(false)

  /**
   * Renders the list of errors found during the questionnaire check.
   *
   * @function renderErrors
   * @returns {JSX.Element[]} The list of rendered error elements.
   */
  const renderErrors = () =>
    checkQuestionnaireErrors.map((item, index) => (
      <div className="flex flex-col" key={index}>
        <p>Error Number: {item.errcode}</p>
        <p>Message: {item.message}</p>
        <p
          className="cursor-pointer text-blue-500"
          onClick={() => handleErrorClick(item["line nbr"])} // Use the handleErrorClick prop
        >
          Line Number: {item["line nbr"]}
        </p>
        <p>Variant: {item.variant}</p>
        <p>Line: {item.line}</p>
        <Divider />
      </div>
    ))

  /**
   * Renders the list of warnings found during the questionnaire check.
   *
   * @function renderWarnings
   * @returns {JSX.Element[]} The list of rendered warning elements.
   */
  const renderWarnings = () =>
    checkQuestionnaireWarnings.map((item, index) => (
      <div className="flex flex-col" key={index}>
        <p>Warning Number: {item.errcode}</p>
        <p>Message: {item.message}</p>
        <p
          className="cursor-pointer text-blue-500"
          onClick={() => handleErrorClick(item["line nbr"])}
        >
          Line Number: {item["line nbr"]}
        </p>
        <p>Variant: {item.variant}</p>
        <p>Line: {item.line}</p>
        <Divider />
      </div>
    ))

  return (
    <div className="flex flex-col h-full p-2 overflow-hidden">
      <div className="flex-shrink-0 flex justify-between items-center mb-2">
        <p className="font-semibold">
          {checkQuestionnaireErrors.length > 0 ||
          checkQuestionnaireWarnings.length > 0 ? (
            <>
              <FontAwesomeIcon
                icon={faCircleExclamation}
                style={{ color: "#A91B0D" }}
              />{" "}
              {checkQuestionnaireErrors.length} Error(s) and{" "}
              {checkQuestionnaireWarnings.length} Warning(s) Found
            </>
          ) : (
            <>
              No Errors or Warnings Found{" "}
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{ color: "#41c219" }}
              />
            </>
          )}
        </p>
        <div>
          <FontAwesomeIcon
            onClick={handleRefreshClick}
            icon={faRefresh}
            className="cursor-pointer mr-2"
          />
          <FontAwesomeIcon
            onClick={handleCloseClick}
            icon={faClose}
            className="cursor-pointer"
          />
        </div>
      </div>
      {isCheckingQuestionnaire ? (
        <div className="flex flex-col items-center justify-center flex-grow">
          <Loader fontSize={24} color="#36C3ED" />
          Checking Questionnaire...
        </div>
      ) : (
        <div className="overflow-y-auto flex-grow">
          {checkQuestionnaireErrors.length > 0 && (
            <>
              <p className="text-md font-semibold">Error(s)</p>
              {renderErrors()}
            </>
          )}
          {checkQuestionnaireWarnings.length > 0 && (
            <>
              <p className="text-md font-semibold">Warning(s)</p>
              {renderWarnings()}
            </>
          )}
        </div>
      )}
    </div>
  )
}
export default QuestionnaireCheckDisplay
