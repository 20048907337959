/**
 * @fileoverview A customizable bar chart component using Nivo charts
 * @requires react
 * @requires @nivo/bar
 * @requires antd
 */

import React, { useState, useRef, useEffect, useCallback } from "react"
import { ResponsiveBar } from "@nivo/bar"
import { Select, Tooltip } from "antd"
import DataQButton from "../Buttons/DataQButton"
import { toPng } from "html-to-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartColumn } from "@fortawesome/free-solid-svg-icons"

const { Option } = Select

/**
 * Custom color scheme for the bar chart
 * @type {string[]}
 */
const customColorScheme = [
  "#36c3ed",
  "#4a90e2",
  "#50e3c2",
  "#f5a623",
  "#b8e986",
  "#bd10e0",
  "#9013fe",
  "#417505",
  "#7ed321",
  "#8b572a",
]

/**
 * Props for the CustomTooltip component
 * @typedef {Object} CustomTooltipProps
 * @property {string} id - The identifier for the data point
 * @property {number|string} value - The value to display
 * @property {string} color - The color of the tooltip
 * @property {string} indexValue - The index value for the data point
 */

/**
 * Custom tooltip component for the bar chart
 * @param {CustomTooltipProps} props - The component props
 * @returns {JSX.Element} A styled tooltip
 */
const CustomTooltip = ({ id, value, color, indexValue }) => (
  <div className="bg-white p-2 rounded-lg">
    <strong style={{ color: "#333" }}>{indexValue}</strong>
    <br />
    <span style={{ color }}>
      {id}: {value}
    </span>
  </div>
)

/**
 * Props for the CustomSelect component
 * @typedef {Object} CustomSelectProps
 * @property {string} placeholder - Placeholder text for the select
 * @property {string[]} value - Currently selected values
 * @property {Function} onChange - Change handler function
 * @property {Array<{value: string, label: string}>} options - Available options
 */

/**
 * Custom select component with tooltip
 * @param {CustomSelectProps} props - The component props
 * @returns {JSX.Element} A select component with tooltip
 */
const CustomSelect = ({ placeholder, value, onChange, options }) => {
  const selected = options.filter((option) => value.includes(option.value))
  const tooltipTitle = selected.map((item) => item.label).join(", ")

  return (
    <Tooltip title={tooltipTitle} placement="bottomLeft">
      <Select
        mode="multiple"
        style={{ width: 200 }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxTagCount={0}
        maxTagPlaceholder={(omittedValues) =>
          `${omittedValues.length} selected`
        }
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Tooltip>
  )
}

/**
 * Props for the BarChart component
 * @typedef {Object} BarChartProps
 * @property {Array<Object>} data - The data to display in the chart
 * @property {string[]} keys - The keys to use for the data
 * @property {string[]} statisticTypes - Available statistic types
 * @property {string} xAxisLabel - Label for the x-axis
 * @property {boolean} isChart - Whether to display as a chart
 */

/**
 * BarChart component for visualizing data
 * @param {BarChartProps} props - The component props
 * @returns {JSX.Element} A responsive bar chart
 */
const BarChart = ({ data, keys, statisticTypes = [], xAxisLabel, isChart }) => {
  const [selectedStatistic, setSelectedStatistic] = useState(null)
  const [chartHeight, setChartHeight] = useState(400)
  const [leftMargin, setLeftMargin] = useState(60)
  const [selectedKeys, setSelectedKeys] = useState(
    keys.filter((key) => key !== "Totals")
  )
  const [selectedCategories, setSelectedCategories] = useState(
    data.filter((item) => item.xValue !== "Totals").map((item) => item.xValue)
  )
  const chartRef = useRef(null)

  // Set initial statistic when statisticTypes changes
  useEffect(() => {
    if (statisticTypes.length > 0 && !selectedStatistic) {
      setSelectedStatistic(statisticTypes[0])
    }
  }, [statisticTypes, selectedStatistic])

  /**
   * Formats tick values based on the selected statistic type
   * @param {number} value - The value to format
   * @returns {string} The formatted value
   */
  const formatTickValue = useCallback(
    (value) => {
      if (!value) return "0"
      if (!selectedStatistic) return value.toLocaleString()

      if (
        selectedStatistic.toLowerCase().includes("perc") ||
        selectedStatistic.toLowerCase().includes("percent") ||
        selectedStatistic.toLowerCase().includes("%")
      ) {
        return `${(value * 100).toFixed(2)}%`
      }
      return value.toLocaleString()
    },
    [selectedStatistic]
  )

  /**
   * Updates chart dimensions based on data and selections
   */
  useEffect(() => {
    if (!selectedStatistic) return

    const calculateChartHeight = () => {
      const baseHeight = 400
      const xLabelLength = Math.max(
        ...selectedCategories.map((item) => item.length)
      )
      const xItemCount = selectedCategories.length
      const additionalHeight =
        Math.max(xLabelLength * 2 - 20, 0) + Math.max(xItemCount * 10 - 50, 0)
      return baseHeight + additionalHeight
    }

    setChartHeight(calculateChartHeight())

    const maxValue = Math.max(
      ...data.flatMap((item) =>
        Object.values(item)
          .filter((val) => typeof val === "object" && val[selectedStatistic])
          .map((val) => val[selectedStatistic] || 0)
      )
    )

    const longestYLabel = formatTickValue(maxValue)
    const newLeftMargin = Math.max(60, longestYLabel.length * 8 + 40)
    setLeftMargin(newLeftMargin)
  }, [data, selectedStatistic, selectedCategories, formatTickValue])

  if (!data || data.length === 0 || keys.length === 0) {
    return <div className="mt-5">No data available for the chart</div>
  }

  if (!isChart || !statisticTypes.length || !selectedStatistic) {
    return (
      <div className="mt-5">
        Unable to display this output as a chart. Please view as table.
      </div>
    )
  }

  /**
   * Prepares chart data based on selected categories and keys
   * @type {Array<Object>}
   */
  const chartData = data
    .filter(
      (item) =>
        selectedCategories.includes(item.xValue) || item.xValue === "Totals"
    )
    .map((item) => {
      const newItem = { xValue: item.xValue }
      keys.forEach((key) => {
        if (selectedKeys.includes(key) || key === "Totals") {
          newItem[key] = (item[key] && item[key][selectedStatistic]) || 0
        }
      })
      return newItem
    })

  /**
   * Handles statistic type selection change
   * @param {string} value - The new statistic type
   */
  const handleStatisticChange = (value) => {
    setSelectedStatistic(value)
  }

  /**
   * Handles keys selection change
   * @param {string[]} values - The new selected keys
   */
  const handleKeysChange = (values) => {
    setSelectedKeys(values)
  }

  /**
   * Handles categories selection change
   * @param {string[]} values - The new selected categories
   */
  const handleCategoriesChange = (values) => {
    setSelectedCategories(values)
  }

  /**
   * Exports the chart as a PNG image
   */
  const exportChart = () => {
    if (chartRef.current) {
      toPng(chartRef.current, { quality: 0.95 })
        .then((dataUrl) => {
          const link = document.createElement("a")
          link.download = "chart.png"
          link.href = dataUrl
          link.click()
        })
        .catch((err) => {
          console.error("Error exporting chart:", err)
        })
    }
  }

  return (
    <div className="w-full mt-5">
      <div className="flex flex-wrap items-end gap-4 mb-5">
        <div className="flex flex-col">
          <label className="mb-1 text-sm font-semibold">Statistic Type</label>
          <Select
            className="w-48"
            value={selectedStatistic || undefined}
            onChange={handleStatisticChange}
          >
            {statisticTypes.map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-col">
          <label className="mb-1 text-sm font-semibold">Variables</label>
          <CustomSelect
            placeholder="Select variables"
            value={selectedKeys}
            onChange={handleKeysChange}
            options={keys
              .filter((key) => key !== "Totals")
              .map((key) => ({
                value: key,
                label: key,
              }))}
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-1 text-sm font-semibold">Categories</label>
          <CustomSelect
            placeholder="Select categories"
            value={selectedCategories}
            onChange={handleCategoriesChange}
            options={data
              .filter((item) => item.xValue !== "Totals")
              .map((item) => ({
                value: item.xValue,
                label: item.xValue,
              }))}
          />
        </div>
        <DataQButton type="primary" onClick={exportChart}>
          Export <FontAwesomeIcon className="ml-2" icon={faChartColumn} />
        </DataQButton>
      </div>
      <div
        className="max-w-full overflow-auto"
        style={{ height: `${chartHeight}px` }}
        ref={chartRef}
      >
        <ResponsiveBar
          data={chartData}
          keys={[...selectedKeys, "Totals"]}
          indexBy="xValue"
          margin={{ top: 50, right: 130, bottom: 120, left: leftMargin }}
          padding={0.3}
          groupMode="grouped"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={({ id }) =>
            customColorScheme[keys.indexOf(id) % customColorScheme.length]
          }
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legend: xAxisLabel,
            legendPosition: "middle",
            legendOffset: 80,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: selectedStatistic,
            legendPosition: "middle",
            legendOffset: -leftMargin + 20,
            format: formatTickValue,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: "#333333",
                },
              },
              legend: {
                text: {
                  fill: "#333333",
                  fontSize: 14,
                },
              },
            },
            legends: {
              text: {
                fill: "#333333",
              },
            },
          }}
          tooltip={({ id, value, color, indexValue }) => (
            <CustomTooltip
              id={id}
              value={formatTickValue(value)}
              color={color}
              indexValue={indexValue}
            />
          )}
        />
      </div>
    </div>
  )
}

export default BarChart
