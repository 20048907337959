import useApiCall from "hooks/useApiCall"
import { pollAsync, fetchAsyncResult } from "api/Engine/data-reporting"

/**
 * Custom hook for managing asynchronous polling and result fetching API calls for data reporting.
 *
 * @returns {Object} - An object containing functions for polling and fetching asynchronous results, along with their loading states.
 *
 * @example
 * const {
 *   pollingAsync,
 *   isPollingAsync,
 *   getAsyncResult,
 *   isFetchingAsyncResult,
 * } = useAsync();
 */
export const useAsync = () => {
  const [pollingAsync, isPollingAsync] = useApiCall(pollAsync)
  const [getAsyncResult, isFetchingAsyncResult] = useApiCall(fetchAsyncResult)

  return {
    pollingAsync,
    isPollingAsync,
    getAsyncResult,
    isFetchingAsyncResult,
  }
}
