import React from "react"
import { List, Input, Checkbox } from "antd"
import parse from "html-react-parser"
import { Loader } from "components/UI/Loader/Loader"

/**
 * SelectedTemplateForm component renders a form for inputting data into a selected template section.
 * It handles both text inputs and list-based inputs with exception handling.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.exceptions - A list of exceptions that can be used in the template.
 * @param {boolean} props.parsingSelectedTemplate - Flag indicating if the template is being parsed.
 * @param {Object} props.section - The current section of the template being rendered.
 * @param {Object} props.values - The current input values for the template fields.
 * @param {Function} props.onChange - Callback function to handle changes in input values.
 * @returns {JSX.Element} The rendered SelectedTemplateForm component.
 */
const SelectedTemplateForm = ({
  exceptions,
  parsingSelectedTemplate,
  section,
  values,
  onChange,
}) => {
  if (parsingSelectedTemplate) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Loader fontSize={24} color="#36C3ED" />
        Parsing template...
      </div>
    )
  }

  /**
   * Handles the change in input values for a specific field.
   *
   * @function handleInputChange
   * @param {string} label - The label of the input field.
   * @param {string} newValue - The new value for the input field.
   */
  const handleInputChange = (label, newValue) => {
    onChange(label, newValue)
  }

  /**
   * Handles the change in exception selections for a specific field.
   *
   * @function handleExceptionChange
   * @param {string} label - The label of the input field.
   * @param {string} exception - The exception to be toggled.
   */
  const handleExceptionChange = (label, exception) => {
    const currentValue = values[label] || ""
    const lines = currentValue.split("\n").filter((line) => line.trim() !== "")
    if (lines.includes(exception)) {
      onChange(label, lines.filter((line) => line !== exception).join("\n"))
    } else {
      onChange(label, [...lines, exception].join("\n") + "\n")
    }
  }

  /**
   * Checks if an exception is currently selected for a specific field.
   *
   * @function isExceptionChecked
   * @param {string} label - The label of the input field.
   * @param {string} exception - The exception to check.
   * @returns {boolean} True if the exception is selected, false otherwise.
   */
  const isExceptionChecked = (label, exception) => {
    const currentValue = values[label] || ""
    return currentValue.split("\n").includes(exception)
  }

  /**
   * Renders the inputs for the current template section.
   *
   * @function renderInputs
   * @returns {JSX.Element} The rendered inputs for the template section.
   */
  const renderInputs = () => {
    return section.inputs.map((input, index) => {
      const placeholder = `{{${input.label}}}`
      const renderedTemplate = section.show.replace(
        new RegExp(placeholder, "g"),
        (values[input.label] || "").replace(/\n/g, "<br />")
      )

      return (
        <div key={index} className="flex flex-col mt-5">
          <div className="flex flex-row mb-5">
            <div className="flex flex-col mr-5 space-y-2 w-full">
              <p className="text-md">
                <strong>Instruction:</strong> {input.instruction}
              </p>
              {input.type === "TEXT" && (
                <Input.TextArea
                  size="large"
                  rows={5}
                  style={{
                    resize: "none",
                    borderRadius: "8px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                  onChange={(e) =>
                    handleInputChange(input.label, e.target.value)
                  }
                  value={values[input.label] || ""}
                />
              )}
              {input.type === "LIST" && (
                <Input.TextArea
                  size="large"
                  rows={5}
                  style={{
                    resize: "none",
                    borderRadius: "8px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                  onChange={(e) =>
                    handleInputChange(input.label, e.target.value)
                  }
                  value={values[input.label] || ""}
                />
              )}
            </div>
            {input.exceptions && (
              <div className="flex w-full flex-col">
                <p className="text-xl">Exceptions to use</p>
                <List
                  className="flex flex-col max-h-96 overflow-y-auto"
                  itemLayout="horizontal"
                  dataSource={exceptions}
                  renderItem={(item, index) => (
                    <List.Item
                      key={index}
                      className="flex cursor-pointer shadow-sm hover:bg-slate-100"
                      onClick={() => handleExceptionChange(input.label, item)}
                      actions={[
                        <Checkbox
                          key={index}
                          className="mr-2"
                          onChange={() =>
                            handleExceptionChange(input.label, item)
                          }
                          checked={isExceptionChecked(input.label, item)}
                        />,
                      ]}
                    >
                      <List.Item.Meta
                        className="p-2 text-left font-semibold "
                        title={item}
                      />
                    </List.Item>
                  )}
                />
              </div>
            )}
          </div>
          <div className="flex flex-row">
            {section.show && (
              <div className="flex flex-col w-full overflow-auto h-52">
                {parse(renderedTemplate)}
              </div>
            )}
          </div>
        </div>
      )
    })
  }

  return <>{renderInputs()}</>
}

export default SelectedTemplateForm
