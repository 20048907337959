import React, { useState, useEffect } from "react"
import { Modal, Progress } from "antd"

/**
 * ProgressModal is a component that displays a modal with a progress bar.
 * The progress bar automatically updates based on a specified duration.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.title - The title to display at the top of the modal.
 * @param {boolean} props.isLoading - A flag indicating whether the loading process is active.
 * @param {number} props.duration - The duration in milliseconds over which the progress bar should fill.
 * @returns {JSX.Element} The rendered ProgressModal component.
 */
const ProgressModal = (props) => {
  const { title, isLoading, duration } = props // Assuming you pass duration in milliseconds
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (isLoading) {
      const start = Date.now()
      const interval = setInterval(() => {
        const elapsed = Date.now() - start
        const percent = Math.min((elapsed / duration) * 100, 100)
        setProgress(Math.round(percent))
        if (percent >= 100) {
          clearInterval(interval)
        }
      }, 100)

      return () => {
        clearInterval(interval)
      }
    } else {
      setProgress(0)
    }
  }, [isLoading, duration])

  return (
    <Modal title={title} open={isLoading} closable={false} footer={null}>
      <div className="flex w-full">
        <Progress percent={progress} status="active" strokeColor="#36C3ED" />
      </div>
    </Modal>
  )
}

export default ProgressModal
