import React from "react"
/**
 * @typedef {Object} Header
 * @property {string} header - The main header text
 * @property {string[]} subHeaders - An array of subheader texts
 */

/**
 * @typedef {Object} TransformedData
 * @property {Header[]} headers - An array of header objects
 * @property {(string|number)[][]} rows - A 2D array representing table rows and cells
 * @property {boolean} hasSubHeaders - Indicates whether the table has subheaders
 */

/**
 * StatisticsTable component renders a dynamic table based on the provided data.
 * It handles various table structures including those with and without subheaders,
 * and special cases with top-left headers.
 *
 * @param {Object} props - The component props
 * @param {TransformedData} props.transformedData - The data structure for the table
 * @returns {JSX.Element} A rendered table component
 */
const StatisticsTable = ({ transformedData }) => {
  const { headers, rows, hasSubHeaders } = transformedData

  /**
   * Determines if there's a special header for the top-left cell
   * @type {boolean}
   */
  const hasTopLeftHeader =
    hasSubHeaders &&
    headers.length > 0 &&
    headers[0].subHeaders &&
    headers[0].subHeaders.length === 2 &&
    headers[0].subHeaders[0] === "" &&
    headers[0].subHeaders[1] === "Statistic"

  /**
   * Styles for table cells
   * @type {Object}
   */
  const cellStyle = {
    border: "1px solid black",
    padding: "10px",
    textAlign: "center",
    minWidth: "150px",
    whiteSpace: "nowrap",
  }

  /**
   * Styles for header cells
   * @type {Object}
   */
  const headerCellStyle = {
    ...cellStyle,
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  }

  return (
    <div style={{ overflowX: "auto" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          tableLayout: "auto",
        }}
      >
        <thead>
          <tr>
            {hasSubHeaders && (
              <th rowSpan={2} style={headerCellStyle}>
                {hasTopLeftHeader ? headers[0].header : ""}
              </th>
            )}
            {hasSubHeaders && (
              <th rowSpan={2} style={headerCellStyle}>
                Statistic
              </th>
            )}
            {headers
              .slice(hasTopLeftHeader ? 1 : 0)
              .map((group, groupIndex) => (
                <th
                  key={groupIndex}
                  colSpan={hasSubHeaders ? group.subHeaders.length : 1}
                  style={headerCellStyle}
                >
                  {group.header}
                </th>
              ))}
          </tr>
          {hasSubHeaders && (
            <tr>
              {headers
                .slice(hasTopLeftHeader ? 1 : 0)
                .flatMap((group, groupIndex) =>
                  group.subHeaders.map((subHeader, subHeaderIndex) => (
                    <th
                      key={`${groupIndex}-${subHeaderIndex}`}
                      style={{ ...headerCellStyle, backgroundColor: "#f7f7f7" }}
                    >
                      {subHeader}
                    </th>
                  ))
                )}
            </tr>
          )}
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => {
                const isFirstColumn = cellIndex === 0
                return (
                  <td
                    key={cellIndex}
                    style={{
                      ...cellStyle,
                      backgroundColor: isFirstColumn ? "#f0f0f0" : "#ffffff",
                      fontWeight: isFirstColumn ? "bold" : "normal",
                    }}
                  >
                    {cell}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default StatisticsTable
