import * as XLSX from "xlsx"

/**
 * Exports data to an Excel file.
 *
 * This function can handle two types of data:
 * 1. Array of Arrays (AOA) for normal tables
 * 2. Array of Objects for translations data
 *
 * For translations data, it creates separate sheets for each language, excluding the design language.
 *
 * @function
 * @param {Array} data - The data to be exported. Can be an array of arrays or an array of objects.
 * @param {string} filename - The name of the file to be created (without extension).
 * @param {Object} [options={}] - Additional options for the export.
 * @param {string} [options.sheetName] - The name of the sheet for AOA data. Defaults to "Sheet1".
 * @param {string} [options.designLanguage] - The design language to be filtered out for translations data.
 * @throws {Error} If the data format is invalid or if the export process fails.
 */
export const exportToExcel = (data, filename, options = {}) => {
  const workbook = XLSX.utils.book_new()
  if (Array.isArray(data) && data.length > 0 && Array.isArray(data[0])) {
    // Handle AOA data (normal tables)
    const worksheet = XLSX.utils.aoa_to_sheet(data)
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      options.sheetName || "Sheet1"
    )
  } else if (
    Array.isArray(data) &&
    data.length > 0 &&
    typeof data[0] === "object"
  ) {
    // Handle translations data
    const languages = new Set()
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (
          key !== "identifier" &&
          key !== "MASTER" &&
          key !== options.designLanguage
        ) {
          languages.add(key)
        }
      })
    })
    languages.forEach((language) => {
      const sheetData = [
        ["Identifier", "Original", language], // Header row
        ...data
          .filter((item) => language in item)
          .map((item) => [item.identifier, item.MASTER, item[language] || ""]),
      ]
      if (sheetData.length > 1) {
        // Only create sheet if there are translations
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData)
        XLSX.utils.book_append_sheet(workbook, worksheet, language)
      }
    })
  } else {
    throw new Error(
      "Invalid data format. Expected array of arrays or array of objects."
    )
  }
  const excelData = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "binary",
  })
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff
    }
    return buf
  }
  const blob = new Blob([s2ab(excelData)], { type: "application/octet-stream" })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url
  a.download = `${filename}.xlsx`
  a.click()
  // Clean up
  setTimeout(() => {
    window.URL.revokeObjectURL(url)
  }, 100)
}
