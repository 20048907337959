import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useProjects } from "hooks/useProjects"
import { useKindeAuth } from "@kinde-oss/kinde-auth-react"

import { useRecoilValue } from "recoil"
import { projectAtom } from "atoms/projectAtom"
import { userAtom } from "atoms/userAtom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

import DataQButton from "components/UI/Buttons/DataQButton"
import ConfirmationModal from "components/UI/ConfimationModal/ConfimationModal"
import CopyProjectModal from "./CopyProjectModal.component"
import {
  SuccessNotification,
  ErrorNotification,
} from "components/UI/Notifications/NotificationTemplate.component"
import ProjectMembersModal from "./ProjectMembers/ProjectMembersModal"

import handleSequenceError from "utils/handleSequenceError"
import MoveProjectModal from "../MoveProjectModal.component"

/**
 * Renders the actions available for a project.
 *
 * This component renders buttons for actions such as deleting the project,
 * archiving it, and managing members.
 *
 * @component
 * @returns {JSX.Element} The rendered ProjectActions component.
 */
const ProjectActions = () => {
  const { deleteUserProject, isDeletingProject } = useProjects()
  const navigate = useNavigate()
  const project = useRecoilValue(projectAtom)
  const user = useRecoilValue(userAtom)

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const { getClaim } = useKindeAuth()
  const userRoles = getClaim("roles")
  const isAdmin =
    userRoles?.value?.some(
      (role) => role.key === "admin" || role.key === "super-admin"
    ) || false
  const isOwner = project.Owner.toLowerCase() === user.username.toLowerCase()

  /**
   * Handles the project deletion process.
   *
   * This function is triggered when the delete button is clicked. It calls the `deleteUserProject` function
   * from the `useProjects` hook and handles success and error notifications accordingly.
   *
   * @async
   * @function deleteProject
   * @param {Event} e - The event object.
   * @returns {Promise<void>} A promise that resolves when the project is successfully deleted or rejects if an error occurs.
   */
  const deleteProject = async (e) => {
    e.preventDefault()
    try {
      const res = await deleteUserProject(project.id)

      if (handleSequenceError(res.data, "Error Deleting Project!")) {
        SuccessNotification(
          "Project Deleted Successfully!",
          "The project has been deleted successfully."
        )
        navigate("/home")
      }
    } catch (error) {
      ErrorNotification("Error Deleting Project!", error.message)
    }
  }

  return (
    <>
      <ConfirmationModal
        title={"Delete Project"}
        isVisible={isDeleteModalVisible}
        cancel={setIsDeleteModalVisible}
        confirm={deleteProject}
        confirmButtonText="Delete"
        confirmButtonType="danger"
        message="Are you sure you want to delete this project? This action cannot be undone."
      />
      <div className="flex flex-wrap gap-1 justify-start items-center ">
        {(isAdmin || isOwner) && <CopyProjectModal />}
        {(isAdmin || isOwner) && <MoveProjectModal />}

        <DataQButton
          onClick={() => setIsDeleteModalVisible(true)}
          type="primary"
          isLoading={isDeletingProject}
        >
          Delete Project
          <FontAwesomeIcon className="ml-2" icon={faTrash} />
        </DataQButton>

        {/* Button to manage project members */}
        <ProjectMembersModal />
      </div>
    </>
  )
}

export default ProjectActions
