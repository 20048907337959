import React from "react"
import { Table, Empty } from "antd"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { userAtom } from "atoms/userAtom"

import getColumns from "./columns"
import LoadingSkeleton from "components/UI/LoadingSkeleton/LoadingSkeleton"

/**
 * ProjectTable component that renders a table displaying project data.
 * It handles both archived and active projects, and provides functionality to unarchive or archive projects.
 * The table also supports navigation to detailed project views for non-archived projects.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Array<Object>} props.projects - The list of projects to display in the table.
 * @param {boolean} props.isLoading - Indicates whether the project data is currently loading.
 * @param {boolean} props.isArchived - Determines if the displayed projects are archived.
 * @param {Function} props.handleUnarchive - Function to handle unarchiving a project.
 * @param {Function} props.handleArchive - Function to handle archiving a project.
 * @returns {JSX.Element} The rendered project table component.
 */
const ProjectTable = (props) => {
  const { projects, isLoading, isArchived, handleUnarchive, handleArchive } =
    props
  const user = useRecoilValue(userAtom)

  const navigate = useNavigate()

  // Ensure projects is always an array
  const dataSource = Array.isArray(projects) ? projects : []
  const columns = getColumns(isArchived, handleUnarchive, handleArchive, user)

  /**
   * Custom component for rendering a better "No data" message.
   * @returns {JSX.Element} A styled "No Data" message with an icon.
   */
  const CustomNoData = () => (
    <div className="p-10">
      <Empty description={<span>No Projects Available.</span>} />
    </div>
  )

  return (
    <Table
      size="small"
      className="project-table"
      rowClassName={isArchived ? "" : "cursor-pointer"}
      rowKey={"id"}
      dataSource={isLoading ? [] : dataSource}
      columns={columns}
      pagination={{ pageSize: 10, hideOnSinglePage: true }}
      locale={{
        emptyText: isLoading ? (
          <LoadingSkeleton type="table" columns={columns} />
        ) : (
          <CustomNoData />
        ),
      }}
      onRow={(record) => ({
        onClick: () => {
          if (!isArchived && record.id) {
            navigate(`/home/${record.id}`)
          }
        },
      })}
    />
  )
}

export default ProjectTable
