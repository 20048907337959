import React, { useState } from "react"
import DataQButton from "components/UI/Buttons/DataQButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHardDrive,
  faPen,
  faPlay,
  faSave,
} from "@fortawesome/free-solid-svg-icons"
import ExportModal from "components/UI/ExportModal/ExportModal"
import ExportDataModal from "./ExportDataModal.component"

/**
 * QueryConsoleActions component renders action buttons for the query console.
 *
 * This component includes buttons for viewing saved queries, running scripts, saving, and exporting scripts.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.toggleSaved - Function to toggle the view between saved queries and the editor.
 * @param {boolean} props.viewSavedQueries - Boolean indicating whether the saved queries view is active.
 * @param {Function} props.runScript - Function to run the script written in the editor.
 * @param {Function} props.saveProjectQuery - Function to save the project query.
 * @param {boolean} props.isRunningScript - Boolean indicating whether the script is currently running.
 * @param {String} props.queryInstance - The query instance ID.
 * @returns {JSX.Element} The rendered query console actions component.
 */
const QueryConsoleActions = ({
  toggleSaved,
  viewSavedQueries,
  runScript,
  saveProjectQuery,
  queryInstance,
  isRunningScript,
}) => {
  const [isSaveQueryModalVisible, setIsSaveQueryModalVisible] = useState(false)

  /**
   * Handles the confirmation of the query save action.
   *
   * @param {string} queryName - The name of the query to be saved.
   */
  const confirmSaveQuery = (queryName) => {
    saveProjectQuery(queryName)
    setIsSaveQueryModalVisible(false)
  }

  return (
    <>
      <ExportModal
        title="Save Query"
        isVisible={isSaveQueryModalVisible}
        confirm={confirmSaveQuery}
        cancel={setIsSaveQueryModalVisible}
        message={"Please enter a filename for your query"}
        ExportButtonText="Save"
      />
      <DataQButton onClick={() => toggleSaved()} type="primary">
        {!viewSavedQueries ? `View Saved Queries` : `View Editor`}
        {!viewSavedQueries ? (
          <FontAwesomeIcon icon={faHardDrive} className="ml-2" />
        ) : (
          <FontAwesomeIcon icon={faPen} className="ml-2" />
        )}
      </DataQButton>
      <DataQButton
        isDisabled={isRunningScript}
        onClick={() => runScript()}
        type="primary"
      >
        Run
        <FontAwesomeIcon icon={faPlay} className="ml-2" />
      </DataQButton>
      <DataQButton
        onClick={() => setIsSaveQueryModalVisible(true)}
        type="primary"
      >
        Save
        <FontAwesomeIcon icon={faSave} className="ml-2" />
      </DataQButton>
      <ExportDataModal queryInstance={queryInstance} />
    </>
  )
}

export default QueryConsoleActions
