import React from "react"
import { Loader } from "components/UI/Loader/Loader"
import OutputTabs from "./OutputTabs.component"

/**
 * Component for displaying the output.
 *
 * This component renders a title and the output content passed as a prop.
 *
 * @param {Object} result - The output content to display.
 * @param {boolean} scriptInProgress - Indicates whether the script is running.
 * @param {boolean} asyncScriptInProgress - Indicates whether the async script is
 * @returns {JSX.Element} The rendered output component.
 *
 */
const Output = ({ result, scriptInProgress, asyncScriptInProgress }) => {
  return (
    <div className="flex flex-col h-full border-t border-l-0 border-r-0 border-b-0 rounded-br-xl overflow-hidden">
      {scriptInProgress || asyncScriptInProgress ? (
        <div className="flex flex-col items-center justify-center h-full">
          <Loader fontSize={48} color="#36C3ED" />
          {asyncScriptInProgress
            ? "Just a moment, your script is running..."
            : "Script is running..."}
        </div>
      ) : (
        <>
          {result?.result && (
            <div className="flex flex-col h-full">
              <div className="flex-grow overflow-auto">
                <OutputTabs result={result.result} />
              </div>
              <div className="flex flex-row w-full p-2 rounded-br-xl bg-white">
                {result.result.log ? (
                  <p className="font-semibold truncate">
                    Log: {result.result.log[0]}
                  </p>
                ) : (
                  <p className="font-semibold">Log: No log available</p>
                )}
                {result.Time && (
                  <p className="ml-auto whitespace-nowrap">
                    Time Taken:{" "}
                    <span className="italic text-[#36C3ED]">
                      {result.Time.toString()}s
                    </span>
                  </p>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
export default Output
