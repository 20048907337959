import React from "react"
import { Tooltip } from "antd"
import { Loader } from "components/UI/Loader/Loader"
import DataQButton from "components/UI/Buttons/DataQButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faCircleExclamation,
  faLock,
  faSave,
} from "@fortawesome/free-solid-svg-icons"

/**
 * EditorFooter component displays the status of the editor and provides a save button.
 *
 * @component
 * @param {Object} props - The component props
 * @param {boolean} props.isLocked - Indicates if the questionnaire is locked for editing
 * @param {boolean} props.isSaving - Indicates if the questionnaire is currently being saved
 * @param {boolean} props.contentChanged - Indicates if there are unsaved changes in the editor
 * @param {Function} props.saveQuestionnaire - Function to trigger saving the questionnaire
 * @returns {JSX.Element} The rendered EditorFooter component
 */
const EditorFooter = ({
  isLocked,
  isSaving,
  contentChanged,
  saveQuestionnaire,
}) => {
  return (
    <div className="flex items-center justify-between bg-white shadow-inner p-4 h-16 rounded-b-xl">
      <div className="flex-grow" />
      <DataQButton
        onClick={saveQuestionnaire}
        type="primary"
        isDisabled={isSaving || !contentChanged || isLocked}
        className="mr-4"
      >
        Save
        <FontAwesomeIcon className="ml-2" icon={faSave} />
      </DataQButton>
      {!isLocked ? (
        <div className="flex items-center">
          {isSaving ? (
            <>
              <Loader fontSize={24} color="#41c219" />
              <p className="font-semibold ml-2">Saving...</p>
            </>
          ) : contentChanged ? (
            <>
              <FontAwesomeIcon
                icon={faCircleExclamation}
                style={{ color: "#e09b20", height: "1.5em" }}
              />
              <p className="font-semibold ml-2">Unsaved changes</p>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faCheck}
                style={{ color: "#41c219", height: "1.5em" }}
              />
              <p className="font-semibold ml-2">Saved</p>
            </>
          )}
        </div>
      ) : (
        <Tooltip title="This questionnaire is being edited by another user and is unable to be saved. Please try again later">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faLock}
              style={{ color: "#e09b20", height: "1.2em" }}
            />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default EditorFooter
