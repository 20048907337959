import React, { useRef, useEffect, useState } from "react"

import { Tooltip } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClose,
  faDownload,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons"

import { Loader } from "components/UI/Loader/Loader"
import DataQButton from "components/UI/Buttons/DataQButton"
import ExportModal from "components/UI/ExportModal/ExportModal"

/**
 * PreviewQuestionnaire component renders a preview of the questionnaire within an iframe and allows the user to export the content to HTML.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isConvertingToWordPreview - Indicates if the preview is being generated.
 * @param {string} props.previewContent - The HTML content to be displayed in the preview.
 * @param {Function} props.downloadHTML - Function to download the HTML content.
 * @param {Function} props.setPreviewVisible - Function to control the visibility of the preview mode.
 * @returns {JSX.Element} The rendered PreviewQuestionnaire component.
 */
const PreviewQuestionnaire = ({
  isConvertingToWordPreview,
  previewContent,
  downloadHTML,
  setPreviewVisible,
}) => {
  const iframeRef = useRef(null)
  const [isHTMLExportModalVisible, setIsHTMLExportModalVisible] =
    useState(false)
  const [isWordExportModalVisible, setIsWordExportModalVisible] =
    useState(false)

  useEffect(() => {
    if (!isConvertingToWordPreview && iframeRef.current) {
      const iframeDoc = iframeRef.current.contentDocument
      iframeDoc.open()
      iframeDoc.write(previewContent)
      iframeDoc.close()
    }
  }, [isConvertingToWordPreview, previewContent])

  /**
   * Handles the confirmation of the HTML export action.
   *
   * @param {string} filename - The name of the file to be exported.
   */
  const confirmHTMLExport = (filename) => {
    downloadHTML(filename, "html")
    setIsHTMLExportModalVisible(false)
  }

  /**
   * Handles the confirmation of the Word export action.
   *
   * @param {string} filename - The name of the file to be exported.
   */
  const confirmWordExport = (filename) => {
    downloadHTML(filename, "word")
    setIsWordExportModalVisible(false)
  }

  return (
    <div className="flex flex-col w-full h-full">
      <ExportModal
        title="Export to HTML"
        confirm={confirmHTMLExport}
        cancel={setIsHTMLExportModalVisible}
        isVisible={isHTMLExportModalVisible}
        message={"Please enter a filename for your export"}
      />
      <ExportModal
        title="Export to Word"
        confirm={confirmWordExport}
        cancel={setIsWordExportModalVisible}
        isVisible={isWordExportModalVisible}
        message={"Please enter a filename for your export"}
      />

      {!isConvertingToWordPreview ? (
        <div className="flex flex-col h-full">
          <div className="flex flex-row h-[48px] shadow-sm w-full p-2">
            <div className="flex ml-auto">
              <Tooltip title="Export as Word" placement="bottomRight">
                <div className="flex">
                  <DataQButton
                    onClick={() => setIsWordExportModalVisible(true)}
                    className="w-7 !h-7 !p-0 my-auto"
                    type="primary"
                  >
                    <FontAwesomeIcon icon={faFileWord} />
                  </DataQButton>
                </div>
              </Tooltip>
              <Tooltip title="Export as HTML" placement="bottomRight">
                <div className="flex">
                  <DataQButton
                    onClick={() => setIsHTMLExportModalVisible(true)}
                    className="w-7 !h-7 !p-0 ml-2"
                    type="primary"
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </DataQButton>
                </div>
              </Tooltip>
              <Tooltip title="Close Preview">
                <FontAwesomeIcon
                  icon={faClose}
                  className="!h-4 !p-0 cursor-pointer my-auto ml-2"
                  onClick={() => setPreviewVisible(false)}
                />
              </Tooltip>
            </div>
          </div>
          <iframe
            ref={iframeRef}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
            title="Preview"
          />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <Loader fontSize={48} color="#36C3ED" />
          Generating preview...
        </div>
      )}
    </div>
  )
}

export default PreviewQuestionnaire
