import { Extension } from "@tiptap/core"
import { Decoration, DecorationSet } from "prosemirror-view"
import { Plugin, PluginKey } from "prosemirror-state"
import keywordStyles from "./keywordStyles"

// Constants
const UPDATE_DEBOUNCE = 300 // Debounce time in milliseconds

// Plugin key for syntax highlighting
const syntaxHighlightPluginKey = new PluginKey("syntaxHighlight")

// Regex patterns
const remarkRegex = /^(#|REMARK)\s*(.*)/gm
const propertyRegex = /\[\[(.*?)\]\]/g
const angleBracketRegex = /<<([^>>]*?)>>/g

// Block keywords and their corresponding highlight classes
const blockKeywords = {
  "ADD LIST": "highlight-item",
  LIST: "highlight-item",
  NOTE: "highlight-note",
  HEADER: "highlight-item",
  ADD: "highlight-item",
  CHANGE: "highlight-item",
  "RANGE LIST": "highlight-item",
  PER: "highlight-item",
  "SELECT ONE FROM": "highlight-item",
  "SELECT MANY FROM": "highlight-item",
  "SELECT MULTIPLE FROM": "highlight-item",
  TRIM: "highlight-item",
  REMOVE: "highlight-item",
  EXTEND: "highlight-item",
}

// Sorted block keywords by length (longer first)
const sortedBlockKeywords = Object.keys(blockKeywords).sort(
  (a, b) => b.length - a.length
)

// Cache for memoization
const memoizedResults = new Map()

// Debounce function
const debounce = (fn, delay) => {
  let timeoutId
  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn(...args), delay)
  }
}

/**
 * Handles special keywords within a line.
 * @param {string} line - The text of the line.
 * @param {number} linePos - The position of the line in the document.
 * @returns {Decoration[]} An array of decorations for the special keywords.
 */
const handleSpecialKeywords = (line, linePos) => {
  let decorations = []
  const specialKeywords = [
    "IF",
    "IF SELECTED",
    "ANY OF",
    "OR",
    "AND",
    "IS",
    "IS NOT",
    "IN",
    "WHERE",
    "NOT",
  ]

  specialKeywords.forEach((keyword) => {
    let regex = new RegExp(`\\b${keyword}\\b`, "g")
    let match

    while ((match = regex.exec(line)) !== null) {
      // Skip 'IN' if it's at the start of the line (ignoring leading whitespace)
      if (
        keyword === "IN" &&
        line.trim().startsWith("IN") &&
        match.index === line.indexOf(line.trim())
      ) {
        continue
      }

      const startKeyword = linePos + match.index
      const endKeyword = startKeyword + keyword.length

      decorations.push(
        Decoration.inline(startKeyword, endKeyword, {
          class: keywordStyles[keyword]?.keywordClass || "highlight-as",
        })
      )
    }
  })

  return decorations
}

/**
 * Handles decorations for block keywords in a line.
 * @param {string} line - The text of the line.
 * @param {number} linePos - The position of the line in the document.
 * @returns {{decorations: Decoration[], insideBlock: string|null}} An object containing decorations and the current block class.
 */
const handleBlockKeyword = (line, linePos) => {
  const blockKeywordMatch = sortedBlockKeywords.find((keyword) =>
    line.trim().startsWith(keyword)
  )
  if (blockKeywordMatch) {
    const startKeyword = linePos
    const endKeyword = startKeyword + blockKeywordMatch.length
    const decorations = [
      Decoration.inline(startKeyword, endKeyword, { class: "highlight-bold" }),
      Decoration.inline(endKeyword, linePos + line.length, {
        class: blockKeywords[blockKeywordMatch],
      }),
    ]
    return { decorations, insideBlock: blockKeywords[blockKeywordMatch] }
  }
  return { decorations: [], insideBlock: null }
}

/**
 * Memoized function for handling general keywords.
 * @param {string} line - The text of the line.
 * @param {number} linePos - The position of the line in the document.
 * @returns {{decorations: Decoration[], lineContainsKeyword: boolean}} An object containing decorations and whether the line contains a keyword.
 */
const handleGeneralKeyword = (line, linePos) => {
  const cacheKey = `${linePos}:${line}`
  if (memoizedResults.has(cacheKey)) return memoizedResults.get(cacheKey)

  let decorations = []
  let lineContainsKeyword = false
  const keywords = Object.keys(keywordStyles).sort(
    (a, b) => b.split(" ").length - a.split(" ").length
  )

  for (const keyword of keywords) {
    const { keywordClass, contentClass } = keywordStyles[keyword]
    const regex = new RegExp(
      keyword === "Q" || keyword === "QUESTION" || keyword === "TEXT"
        ? `^(${keyword})(\\s+[^:]*)(:)?(.*)`
        : keyword === "NOTE"
        ? `^(NOTE)\\s*(.*?):\\s*(.*)`
        : keyword === "DATA"
        ? `^(DATA)(\\s+.*)?`
        : `\\b(${keyword})(\\s.*)?`,
      "g"
    )

    let match
    while ((match = regex.exec(line)) !== null) {
      lineContainsKeyword = true
      const startKeyword = linePos + match.index
      const endKeyword = startKeyword + match[1].length
      decorations.push(
        Decoration.inline(startKeyword, endKeyword, { class: keywordClass })
      )

      if (match[2]) {
        const startContent = endKeyword
        const endContent = linePos + match.index + match[0].length
        if (
          (keyword === "Q" || keyword === "QUESTION" || keyword === "TEXT") &&
          match[3] === ":"
        ) {
          decorations.push(
            Decoration.inline(startContent, startContent + match[2].length, {
              class: "highlight-item",
            }),
            Decoration.inline(startContent + match[2].length + 1, endContent, {
              class: contentClass,
            })
          )
        } else {
          decorations.push(
            Decoration.inline(startContent, endContent, { class: contentClass })
          )
        }
      }
    }
    if (lineContainsKeyword) break
  }

  const result = { decorations, lineContainsKeyword }
  memoizedResults.set(cacheKey, result)
  return result
}

/**
 * Handles decorations for a line.
 * @param {string} line - The text of the line.
 * @param {number} linePos - The position of the line in the document.
 * @param {string|null} insideBlock - The current block class if inside a block.
 * @returns {{decorations: Decoration[], insideBlock: string|null}} An object containing decorations and the current block class.
 */
const handleLineDecorations = (line, linePos, insideBlock) => {
  let decorations = []
  decorations = decorations.concat(handleSpecialKeywords(line, linePos))

  let { decorations: blockDecorations, insideBlock: newInsideBlock } =
    handleBlockKeyword(line, linePos)
  decorations = decorations.concat(blockDecorations)

  if (insideBlock && !line.trim()) {
    decorations.push(
      Decoration.inline(linePos, linePos + line.length, { class: insideBlock })
    )
    return { decorations, insideBlock }
  }

  if (!newInsideBlock) {
    const { decorations: generalDecorations, lineContainsKeyword } =
      handleGeneralKeyword(line, linePos)
    decorations = decorations.concat(generalDecorations)

    if (!lineContainsKeyword && line.trim()) {
      decorations.push(
        Decoration.inline(linePos, linePos + line.length, {
          class: insideBlock,
        })
      )
    } else {
      insideBlock = null
    }
  }

  return { decorations, insideBlock: newInsideBlock || insideBlock }
}

/**
 * Handles remark lines separately and with highest priority.
 * @param {Node} doc - The ProseMirror document node.
 * @param {number} from - Start position of the range to process.
 * @param {number} to - End position of the range to process.
 * @returns {Decoration[]} The decorations for remark lines.
 */
const handleRemarkLines = (doc, from, to) => {
  let remarkDecorations = []
  doc.nodesBetween(from, to, (node, pos) => {
    if (node.isText) {
      const nodeFrom = Math.max(from, pos)
      const nodeTo = Math.min(to, pos + node.nodeSize)
      const text = node.text.slice(nodeFrom - pos, nodeTo - pos)
      let match
      while ((match = remarkRegex.exec(text)) !== null) {
        const startRemark = nodeFrom + match.index
        const endRemark = startRemark + match[0].length
        remarkDecorations.push(
          Decoration.inline(startRemark, endRemark, {
            class: "highlight-remark",
          })
        )
      }
    }
    return true
  })
  return remarkDecorations
}

const FontChangeExtension = Extension.create({
  name: "syntaxHighlighting",

  addOptions() {
    return {
      largeFileThreshold: 150000, // characters
    }
  },

  addProseMirrorPlugins() {
    const { largeFileThreshold } = this.options

    const updateDecorations = (doc) => {
      const decos = []
      let insideBlock = null

      doc.descendants((node, pos) => {
        if (node.isText) {
          const text = node.text
          const lines = text.split("\n")
          let currentPos = pos

          lines.forEach((line) => {
            const lineFrom = currentPos
            const lineTo = lineFrom + line.length

            if (
              !line.trim().startsWith("#") &&
              !line.trim().startsWith("REMARK")
            ) {
              const result = handleLineDecorations(line, lineFrom, insideBlock)
              decos.push(...result.decorations)
              insideBlock = result.insideBlock

              let match
              while ((match = propertyRegex.exec(line)) !== null) {
                decos.push(
                  Decoration.inline(
                    lineFrom + match.index,
                    lineFrom + match.index + match[0].length,
                    { class: "highlight-validate" }
                  )
                )
              }
              while ((match = angleBracketRegex.exec(line)) !== null) {
                decos.push(
                  Decoration.inline(
                    lineFrom + match.index,
                    lineFrom + match.index + match[0].length,
                    { class: "highlight-validate" }
                  )
                )
              }
            }
            currentPos = lineTo + 1
          })
        }
        return true
      })

      const remarkDecos = handleRemarkLines(doc, 0, doc.content.size)

      return DecorationSet.create(doc, [...decos, ...remarkDecos])
    }

    const debouncedUpdateDecorations = debounce((state, dispatch) => {
      const decorations = updateDecorations(state.doc)
      dispatch(state.tr.setMeta(syntaxHighlightPluginKey, { decorations }))
    }, UPDATE_DEBOUNCE)

    return [
      new Plugin({
        key: syntaxHighlightPluginKey,
        state: {
          init(_, { doc }) {
            return updateDecorations(doc)
          },
          apply(tr, oldState) {
            const meta = tr.getMeta(syntaxHighlightPluginKey)
            if (meta && meta.decorations) {
              return meta.decorations
            }
            return oldState.map(tr.mapping, tr.doc)
          },
        },
        props: {
          decorations(state) {
            return this.getState(state)
          },
        },
        view() {
          return {
            update: (view, prevState) => {
              if (view.state.doc !== prevState.doc) {
                const docSize = view.state.doc.content.size
                if (docSize > largeFileThreshold) {
                  debouncedUpdateDecorations(view.state, view.dispatch)
                } else {
                  const decorations = updateDecorations(view.state.doc)
                  view.dispatch(
                    view.state.tr.setMeta(syntaxHighlightPluginKey, {
                      decorations,
                    })
                  )
                }
              }
            },
          }
        },
      }),
    ]
  },
})
export default FontChangeExtension
