import React, { useEffect, useState } from "react"
import { useEditor } from "@tiptap/react"
import Split from "react-split"

import Document from "@tiptap/extension-document"
import Text from "@tiptap/extension-text"
import Paragraph from "@tiptap/extension-paragraph"

import { useRecoilValue } from "recoil"
import { projectAtom } from "atoms/projectAtom"

import Console from "components/Home/DataReporting/Console.component"
import QueryConsoleActions from "components/Home/DataReporting/QueryConsoleActions.component"
import DataTree from "components/Home/DataReporting/DataTree.component"
import SavedQueries from "components/Home/DataReporting/SavedQueries.component"
import InvalidData from "components/Home/DataReporting/InvalidData.component"
import { Loader } from "components/UI/Loader/Loader"
import { SuccessNotification } from "components/UI/Notifications/NotificationTemplate.component"

import { useEngine } from "hooks/useEngine"
import { useQueries } from "hooks/useQueries"
import { useDataImport } from "hooks/useDataImport"
import { useAsync } from "hooks/useAsync"
import handleSequenceError from "utils/handleSequenceError"

/**
 * Container component for the Query Console.
 *
 * This component manages the query console interface, where users can write, run, and save scripts.
 * It handles data loading, query fetching, and console rendering.
 *
 * @component
 * @returns {JSX.Element} The rendered query console container component.
 */
const QueryConsoleContainer = () => {
  const project = useRecoilValue(projectAtom)
  const {
    initialiseData,
    isInitialisingData,
    RunEngineQuery,
    isRunningEngineQuery,
  } = useEngine()
  const { getDataFilesUploaded, isGettingDataFilesUploaded } = useDataImport()
  const { getProjectQueries, isLoadingQueries, saveQuery } = useQueries()
  const { pollingAsync, getAsyncResult } = useAsync()

  const [result, setResult] = useState({})
  const [isRunningScript, setIsRunningScript] = useState(false)
  const [isCodebookLoading, setIsCodebookLoading] = useState(false)
  const [viewSavedQueries, setViewSavedQueries] = useState(false)
  const [dataUploaded, setDataUploaded] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(true)

  const [codebook, setCodebook] = useState([])
  const [queryInstance, setQueryInstance] = useState("")
  const [projectQueries, setProjectQueries] = useState([])

  // Initialize the editor instance with necessary extensions
  const editor = useEditor({
    extensions: [Document, Text, Paragraph],
    content: "",
    editorProps: {
      attributes: {
        class: "console-font",
      },
    },
  })

  const pageLoading =
    !editor ||
    isGettingDataFilesUploaded ||
    isInitialisingData ||
    isLoadingQueries ||
    isDataLoading
  const scriptInProgress = isRunningEngineQuery && isRunningScript
  const asyncScriptInProgress = !isRunningEngineQuery && isRunningScript

  useEffect(() => {
    getInitialData()
    fetchQueries()
  }, [])

  /**
   * Fetches the initial data required to run queries.
   * This includes checking for uploaded data files and initializing the data.
   *
   * @async
   * @function getInitialData
   */
  const getInitialData = async () => {
    try {
      const fetchRes = await getDataFilesUploaded(
        project.id,
        project.Created_by,
        "json",
        "size"
      )
      if (fetchRes.data) {
        const initialiseRes = await initialiseData(project.id)
        if (
          handleSequenceError(initialiseRes.data, "Error Initializing Data!")
        ) {
          setQueryInstance(initialiseRes.data)
          setIsCodebookLoading(true)
          const codebookRes = await RunEngineQuery(
            initialiseRes.data,
            "CODEBOOK",
            false
          )
          if (
            handleSequenceError(codebookRes.data, "Error Creating Codebook!")
          ) {
            setCodebook(codebookRes.data.result.output[0].content.value)
            setDataUploaded(true) // Set dataUploaded to true only if the data is successfully fetched
          }
          setIsCodebookLoading(false)
        }
      }
    } catch (fetchError) {
      console.error("Error fetching data:", fetchError)
    } finally {
      setIsDataLoading(false) // Set data loading state to false once the data is fetched
    }
  }

  /**
   * Fetches the saved queries for the current project.
   *
   * @async
   * @function fetchQueries
   */
  const fetchQueries = async () => {
    try {
      const queryRes = await getProjectQueries(project.id, project.Created_by)
      if (queryRes.data) {
        let queryData = queryRes.data.toString().split("\n")
        setProjectQueries(queryData)
      } else {
        setProjectQueries([])
      }
    } catch (error) {
      console.error("Error fetching queries:", error)
    }
  }

  /**
   * Runs the script written in the editor.
   * This function fetches the result of the query execution and updates the state.
   *
   * @async
   * @function runScript
   */
  const runScript = async () => {
    try {
      setIsRunningScript(true)

      let script = editor.getText().replace(/\n\n+/g, "\n")
      const result = await RunEngineQuery(queryInstance, script, true) // Call the async function

      if (result.status === 202) {
        // Handle async polling if query is in progress

        pollForAsyncResult(result.data)
      } else {
        setResult(result.data) // If immediate result, set it directly
        setIsRunningScript(false)
      }
    } catch (error) {
      console.error("An error occurred while running the script:", error)
      setIsRunningScript(false)
    }
  }

  /**
   * Polls for the result of an asynchronous operation using a provided async ID.
   * Retries until the result is ready or an error occurs.
   *
   * @async
   * @function
   * @param {string} asyncID - The unique identifier for the asynchronous operation.
   * @returns {Promise<void>} No return value.
   *
   * @example
   * // Example usage:
   * pollForAsyncResult('12345')
   *
   * @throws Will log an error if polling or result fetching fails.
   */
  const pollForAsyncResult = async (asyncID) => {
    try {
      const pollResult = await pollingAsync(asyncID)
      if (pollResult.status === 200) {
        // The async result is ready; fetch the result
        const asyncResult = await getAsyncResult(asyncID)
        setResult(asyncResult.data) // Set the result in the state
        setIsRunningScript(false)
      } else {
        // Retry polling after delay if not ready
        setTimeout(() => pollForAsyncResult(asyncID), 5000) // Adjust delay as needed
      }
    } catch (error) {
      console.error("Polling error:", error)
      setIsRunningScript(false)
    }
  }
  /**
   * Saves the current script as a project query.
   *
   * @async
   * @function saveProjectQuery
   * @param {string} queryName - The name under which the query will be saved.
   */
  const saveProjectQuery = async (queryName) => {
    let query = editor
      .getHTML()
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "\n")
      .replace(/&lt;&lt;/g, "<<")
      .replace(/&gt;&gt;/g, ">>")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&")
      .trim()

    try {
      const saveRes = await saveQuery(
        project.id,
        project.Created_by,
        queryName,
        "overwrite",
        query
      )
      if (handleSequenceError(saveRes.data, "Error Saving Query!")) {
        SuccessNotification(
          "Query saved successfully!",
          "View Saved Queries to access your saved queries."
        )
        fetchQueries() // Refresh the queries
      }
    } catch (error) {
      console.error("Error saving query:", error)
    }
  }

  /**
   * Regenerates the codebook by rerunning the initial query.
   *
   * @async
   * @function regenerateCodebook
   */
  const regenerateCodebook = async () => {
    try {
      setIsCodebookLoading(true)
      const codebookRes = await RunEngineQuery(queryInstance, "CODEBOOK", false)
      if (handleSequenceError(codebookRes, "Error Creating Codebook!")) {
        setCodebook(codebookRes.data.result.output[0].content.value)
      }
      setIsCodebookLoading(false)
    } catch (error) {
      console.error("Error regenerating codebook:", error)
    }
  }

  /**
   * Toggles the view between saved queries and the editor.
   *
   * @function toggleSaved
   */
  const toggleSaved = () => {
    setViewSavedQueries(!viewSavedQueries)
  }

  let loadingMessage = "Loading"

  if (!editor) {
    loadingMessage = "Loading Editor..."
  } else if (isGettingDataFilesUploaded) {
    loadingMessage = "Checking Data File..."
  } else if (isInitialisingData) {
    loadingMessage = "Initializing Data File..."
  }

  if (pageLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Loader fontSize={48} color="#36C3ED" />
        {loadingMessage}
      </div>
    )
  }

  if (!isDataLoading && !dataUploaded) {
    return <InvalidData />
  } else {
    return (
      <div className="flex flex-col h-full">
        <div className="flex p-2 ml-auto space-x-2">
          <QueryConsoleActions
            queryInstance={queryInstance}
            viewSavedQueries={viewSavedQueries}
            toggleSaved={toggleSaved}
            runScript={runScript}
            isRunningScript={isRunningScript}
            saveProjectQuery={saveProjectQuery}
          />
        </div>
        <Split
          className="flex flex-grow overflow-hidden"
          direction="horizontal"
          gutterSize={10}
          sizes={[25, 75]}
        >
          <div className="w-1/4 border flex flex-col overflow-hidden">
            <DataTree
              editor={editor}
              codebook={codebook}
              isCodebookLoading={isCodebookLoading}
              regenerateCodebook={regenerateCodebook}
            />
          </div>
          <div className="flex-1 flex flex-col overflow-hidden">
            {viewSavedQueries ? (
              <SavedQueries
                project={project}
                toggleSaved={toggleSaved}
                projectQueries={projectQueries}
                editor={editor}
                refreshQueries={fetchQueries}
              />
            ) : (
              <Console
                editor={editor}
                result={result}
                scriptInProgress={scriptInProgress}
                asyncScriptInProgress={asyncScriptInProgress}
              />
            )}
          </div>
        </Split>
      </div>
    )
  }
}

export default QueryConsoleContainer
