import React, { useState, useEffect } from "react"
import { Modal, Typography, List, Checkbox } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import DataQButton from "components/UI/Buttons/DataQButton"
import { faTimeline, faTrash } from "@fortawesome/free-solid-svg-icons"

/**
 * SelectVariantModal is a modal component for selecting a variant within a project.
 * Users can choose from a list of available variants, including a default "Master" variant.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.project - The project object containing project details.
 * @param {Array<Object>} props.variants - The array of variant objects available for selection.
 * @param {Object} props.selectedVariant - The currently selected variant object.
 * @param {Function} props.setSelectedVariant - Function to set the selected variant.
 * @param {Function} props.handleDeleteVariant - Function to handle the deletion of a variant.
 * @param {Function} props.refresh - Function to refresh the project data.
 * @param {Function} props.setPreviewVisible - Function to set the visibility of the preview.
 * @returns {JSX.Element} The rendered SelectVariantModal component.
 */
const SelectVariantModal = (props) => {
  const {
    project,
    variants,
    handleDeleteVariant,
    selectedVariant,
    setSelectedVariant,
    refresh,
    setPreviewVisible,
  } = props
  const { Title } = Typography
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [tempSelectedVariant, setTempSelectedVariant] =
    useState(selectedVariant)

  /**
   * Handles the variant selection and updates the state.
   *
   * @function selectVariantCallback
   */
  const selectVariantCallback = () => {
    setPreviewVisible(false)
    setSelectedVariant(tempSelectedVariant)
    setIsModalVisible(false)
    refresh(project.id)
  }

  /**
   * Cancels the variant selection and closes the modal.
   *
   * @function cancelVariant
   */
  const cancelVariant = () => {
    setIsModalVisible(false)
  }

  /**
   * Shows the modal for selecting a variant.
   *
   * @function showModal
   */
  const showModal = () => {
    setIsModalVisible(true)
  }

  /**
   * Handles the selection of a variant.
   *
   * @function handleVariantSelection
   * @param {Object} variant - The selected variant object.
   */
  const handleVariantSelection = (variant) => {
    if (variant.name === "Master") {
      setTempSelectedVariant({})
    } else {
      setTempSelectedVariant(variant)
    }
  }

  // Update variants list when the variants prop changes
  useEffect(() => {}, [variants])

  // Add default "Master" variant
  const masterVariant = { name: "Master", languages: project.Languages }
  const updatedVariants = [masterVariant, ...variants]

  return (
    <>
      <DataQButton
        className="w-3/6 shadow-sm flex items-center"
        onClick={showModal}
        type="primary"
      >
        <FontAwesomeIcon icon={faTimeline} className="mr-2" />
        Selected Variant:
        <p className="font-semibold whitespace-nowrap inline-block ml-1">
          {Object.keys(selectedVariant).length === 0
            ? "Master"
            : selectedVariant.name}
        </p>
      </DataQButton>
      <Modal
        open={isModalVisible}
        centered={true}
        footer={false}
        closable={false}
        destroyOnClose={true}
      >
        <Title style={{ marginTop: "0.5em" }} level={4}>
          Select Variant
        </Title>
        <div className="max-h-96 overflow-auto">
          <List
            className="variant-select"
            itemLayout="horizontal"
            dataSource={updatedVariants}
            renderItem={(item) => (
              <List.Item
                onClick={() => handleVariantSelection(item)}
                className="cursor-pointer hover:bg-gray-100 rounded-2xl"
                actions={[
                  <Checkbox
                    key={item.name}
                    className="mr-2"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleVariantSelection(item)
                    }}
                    checked={
                      tempSelectedVariant.name
                        ? tempSelectedVariant.name === item.name
                        : item.name === "Master"
                    }
                  />,
                  item.name !== "Master" && (
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="text-red-500 mr-4"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleDeleteVariant(item)
                      }}
                    />
                  ),
                ]}
              >
                <List.Item.Meta
                  className="p-2 ml-2"
                  title={item.name}
                  description={`Languages:  ${item.languages
                    .map((language) => language)
                    .join(", ")}`}
                />
              </List.Item>
            )}
          />
        </div>
        <div className="flex flex-row mt-5">
          <DataQButton
            className="ml-auto mr-2"
            type="primary"
            onClick={() => selectVariantCallback()}
          >
            Select Variant
          </DataQButton>
          <DataQButton type="secondary" onClick={() => cancelVariant()}>
            Cancel
          </DataQButton>
        </div>
      </Modal>
    </>
  )
}

export default SelectVariantModal
