import useApiCall from "hooks/useApiCall"
import {
  getProjects,
  createProject,
  deleteProject,
  updateProject,
  projectMembers,
  projectMemberAdd,
  projectMemberRemove,
  getProjectInfo,
  archiveProject,
  unarchiveProject,
  availableArchivedProjects,
  copyProject,
  moveProject,
} from "api/Repos/projects"

/**
 * Custom hook for managing project-related API calls and their loading states.
 *
 * @returns {Object} - An object containing functions for various project-related API calls and their loading states.
 *
 * @example
 * const {
 *   getUserProjects,
 *   isLoadingProjects,
 *   createUserProject,
 *   isCreatingProject,
 *   deleteUserProject,
 *   isDeletingProject,
 *   updateUserProject,
 *   isUpdatingProject,
 *   getProjectMembers,
 *   isLoadingMembers,
 *   addProjectMember,
 *   isAddingMember,
 *   removeProjectMember,
 *   isRemovingMember,
 *   getProjectDetails,
 *   isLoadingProjectDetails,
 * } = useProjects();
 */
export const useProjects = () => {
  const [getUserProjects, isLoadingProjects] = useApiCall(getProjects)
  const [createUserProject, isCreatingProject] = useApiCall(createProject)
  const [deleteUserProject, isDeletingProject] = useApiCall(deleteProject)
  const [updateUserProject, isUpdatingProject] = useApiCall(updateProject)
  const [getProjectMembers, isLoadingMembers] = useApiCall(projectMembers)
  const [addProjectMember, isAddingMember] = useApiCall(projectMemberAdd)
  const [removeProjectMember, isRemovingMember] =
    useApiCall(projectMemberRemove)
  const [getProjectDetails, isLoadingProjectDetails] =
    useApiCall(getProjectInfo)
  const [archiveUserProject, isArchivingProject] = useApiCall(archiveProject)
  const [unarchiveUserProject, isUnarchivingProject] =
    useApiCall(unarchiveProject)
  const [getArchivedProjects, isLoadingArchivedProjects] = useApiCall(
    availableArchivedProjects
  )
  const [copyUserProject, isCopyingProject] = useApiCall(copyProject)
  const [moveUserProject, isMovingProject] = useApiCall(moveProject)

  return {
    /**
     * Fetches the list of projects associated with the current user.
     *
     * @function getUserProjects
     * @param {string} filter - The filter to apply to the projects (e.g., owned, member, both, all).
     * @returns {Promise<Object>} - The list of projects.
     * @throws Will throw an error if the request fails.
     */
    getUserProjects,

    /**
     * Indicates whether the project list is currently being fetched.
     *
     * @type {boolean}
     */
    isLoadingProjects,

    /**
     * Creates a new project for the user.
     *
     * @function createUserProject
     * @param {Object} body - The details of the project to create.
     * @param {string} body.name - The name of the project.
     * @param {string} body.goal - The goal of the project.
     * @returns {Promise<Object>} - The created project's details.
     * @throws Will throw an error if the creation fails.
     */
    createUserProject,

    /**
     * Indicates whether a project is currently being created.
     *
     * @type {boolean}
     */
    isCreatingProject,

    /**
     * Deletes a specific project.
     *
     * @function deleteUserProject
     * @param {string} id - The ID of the project to delete.
     * @returns {Promise<Object>} - The response from the server.
     * @throws Will throw an error if the deletion fails.
     */
    deleteUserProject,

    /**
     * Indicates whether a project is currently being deleted.
     *
     * @type {boolean}
     */
    isDeletingProject,

    /**
     * Updates the details of an existing project.
     *
     * @function updateUserProject
     * @param {Object} body - The updated details of the project.
     * @param {string} body.id - The ID of the project to update.
     * @param {string} body.name - The name of the project.
     * @param {string} body.goal - The goal of the project.
     * @param {string} body.status - The status of the project.
     * @param {string} body.designLanguage - The design language of the project.
     * @param {Array<string>} body.languages - The languages of the project.
     * @param {string} body.type - The type of the project.
     * @returns {Promise<Object>} - The updated project's details.
     * @throws Will throw an error if the update fails.
     */
    updateUserProject,

    /**
     * Indicates whether a project is currently being updated.
     *
     * @type {boolean}
     */
    isUpdatingProject,

    /**
     * Fetches the members of a specific project.
     *
     * @function getProjectMembers
     * @param {string} id - The ID of the project.
     * @returns {Promise<Object>} - The list of project members.
     * @throws Will throw an error if the request fails.
     */
    getProjectMembers,

    /**
     * Indicates whether the project members are currently being fetched.
     *
     * @type {boolean}
     */
    isLoadingMembers,

    /**
     * Adds a member to a specific project.
     *
     * @function addProjectMember
     * @param {string} id - The ID of the project.
     * @param {string} member_name - The name of the member to add.
     * @returns {Promise<Object>} - The response from the server.
     * @throws Will throw an error if the request fails.
     */
    addProjectMember,

    /**
     * Indicates whether a member is currently being added to the project.
     *
     * @type {boolean}
     */
    isAddingMember,

    /**
     * Removes a member from a specific project.
     *
     * @function removeProjectMember
     * @param {string} id - The ID of the project.
     * @param {string} member_name - The name of the member to remove.
     * @returns {Promise<Object>} - The response from the server.
     * @throws Will throw an error if the request fails.
     */
    removeProjectMember,

    /**
     * Indicates whether a member is currently being removed from the project.
     *
     * @type {boolean}
     */
    isRemovingMember,

    /**
     * Fetches the details of a specific project.
     *
     * @function getProjectDetails
     * @param {string} id - The ID of the project.
     * @returns {Promise<Object>} - The project's details.
     * @throws Will throw an error if the request fails.
     */
    getProjectDetails,

    /**
     * Indicates whether the project details are currently being fetched.
     *
     * @type {boolean}
     */
    isLoadingProjectDetails,

    /**
     * Archives a specific project.
     *
     * @function archiveUserProject
     * @param {string} id - The ID of the project to archive.
     * @param {string} owner - The owner of the project.
     * @returns {Promise<Object>} - The response from the server.
     * @throws Will throw an error if the request fails.
     */
    archiveUserProject,

    /**
     * Indicates whether a project is currently being archived.
     *
     * @type {boolean}
     */
    isArchivingProject,

    /**
     * Unarchives a specific project.
     *
     * @function unarchiveUserProject
     * @param {string} id - The ID of the project to unarchive.
     * @param {string} owner - The owner of the project.
     * @returns {Promise<Object>} - The response from the server.
     * @throws Will throw an error if the request fails.
     */

    unarchiveUserProject,

    /**
     * Indicates whether a project is currently being unarchived.
     *
     * @type {boolean}
     */

    isUnarchivingProject,

    /**
     * Fetches the list of archived projects.
     *
     * @function getArchivedProjects
     * @param {string} filter - The filter to apply to the archived projects.
     * @returns {Promise<Object>} - The list of archived projects.
     * @throws Will throw an error if the request fails.
     */

    getArchivedProjects,

    /**
     * Indicates whether the archived projects are currently being fetched.
     *
     * @type {boolean}
     */

    isLoadingArchivedProjects,

    /**
     * Copies a specific project.
     *
     * @function copyUserProject
     *  @param {string} id - The project ID.
     *  @param {string} owner - The owner of the project.
     *  @param {string} copy - The fields to copy (e.g., metadata, qnr, data, docs, queries).
     *  @param {string} name - The new name for the copied project, if empty will append -copy to original project name.
     *  @param {string} newOwner - The owner of the copied project.
     *  @returns {Promise<Object>} - The response from the server.
     * @throws Will throw an error if the request fails.
     */

    copyUserProject,

    /**
     * Indicates whether a project is currently being copied.
     *
     * @type {boolean}
     */

    isCopyingProject,

    /**
     * Moves a project to a new owner.
     *
     * @function moveUserProject
     * @param {string} id - The project ID.
     * @param {string} owner - The current owner of the project.
     * @param {string} newOwner - The new owner of the project.
     * @returns {Promise<Object>} - The response from the server.
     * @throws Will throw an error if the request fails.
     */

    moveUserProject,

    /**
     * Indicates whether a project is currently being moved.
     *
     * @type {boolean}
     */

    isMovingProject,
  }
}
