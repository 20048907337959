import axios from "../request"
import JSZip from "jszip"
import { fileToBase64 } from "utils/fileToBase64"

/**
 * Uploads a document.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user identifier of the requester.
 * @param {string} unit - The unit identifier of the requester.
 * @param {string} role - The role of the requester.
 * @param {string} id - The project ID.
 * @param {string} owner - The owner of the project.
 * @param {string} filename - The name of the file to be uploaded.
 * @param {File} file - The file object to be uploaded.
 * @returns {Promise<Object>} - The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function documentUpload(
  token,
  user,
  unit,
  role,
  id,
  owner,
  filename,
  file
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }

  // Initialize JSZip instance
  const zip = new JSZip()

  // Add file content to zip
  zip.file(filename, file, { compression: "DEFLATE" })

  const zippedContent = await zip.generateAsync({
    type: "blob",
    compression: "DEFLATE",
  })

  let zippedBase64 = await fileToBase64(zippedContent)

  const encoder = new TextEncoder()
  const encoded = encoder.encode(zippedBase64)

  const response = await axios.post(
    `/document-upload?id=${id}&hint_owner=${owner}&name=${filename}&encode=base64_zip`,
    encoded,
    config
  )
  return response
}
/**
 * Delete a document.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user identifier of the requester.
 * @param {string} unit - The unit identifier of the requester.
 * @param {string} role - The role of the requester.
 * @param {string} id - The project ID.
 * @param {string} owner - The owner of the project.
 * @param {string} filename - The name of the file to be deleted.
 * @returns {Promise<Object>} - The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function documentDelete(
  token,
  user,
  unit,
  role,
  id,
  owner,
  filename
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }

  const response = await axios.delete(
    `/document-delete?id=${id}&hint_owner=${owner}&name=${filename}`,
    config
  )
  return response
}
/**
 * Download a document.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user identifier of the requester.
 * @param {string} unit - The unit identifier of the requester.
 * @param {string} role - The role of the requester.
 * @param {string} id - The project ID.
 * @param {string} owner - The owner of the project.
 * @param {string} filename - The name of the file to be downloaded.
 * @param {string} type - The type of the file to be downloaded (either 'text', 'base64', or 'base64_zip').
 * @returns {Promise<void>} - Resolves when the download is triggered.
 * @throws Will throw an error if the request fails.
 */
export async function documentDownload(
  token,
  user,
  unit,
  role,
  id,
  owner,
  filename
) {
  try {
    const config = {
      headers: {
        token,
        user,
        unit,
        role,
      },
      responseType: "text", // Expecting base64 encoded string
    }

    // Fetch the base64 encoded zip content from the API
    const response = await axios.get(
      `/document-download?id=${id}&hint_owner=${owner}&name=${filename}&encode=base64_zip`,
      config
    )

    const base64Data = response.data.trim() // Get the base64-encoded string from the server

    // 1. Decode the base64 string to a binary string
    const binaryString = window.atob(base64Data) // This decodes the base64 string into a binary string

    // 2. Convert the binary string to Uint8Array (JSZip expects binary data)
    const len = binaryString.length
    const uint8Array = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      uint8Array[i] = binaryString.charCodeAt(i) // This step converts the binary string into a Uint8Array
    }

    // Initialize JSZip and load the binary data
    const zip = new JSZip()
    const zipContent = await zip.loadAsync(uint8Array) // Load the binary data into JSZip

    // Get the first file in the zip archive (assuming single file)
    const unzippedFile = Object.keys(zipContent.files)[0]
    const unzippedData = await zip.file(unzippedFile).async("blob")

    // Create a link element to download the unzipped file
    const url = window.URL.createObjectURL(unzippedData)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", unzippedFile) // Set the filename for download

    // Append the link to the body
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
    link.remove()
  } catch (error) {
    console.error("Error in downloading or unzipping document:", error.message)
    throw error
  }
}
/**
 * Retrieves the list of documents for a given project.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user identifier.
 * @param {string} unit - The unit identifier.
 * @param {string} role - The role of the requester.
 * @param {string} id - The project ID.
 * @param {string} owner - The owner of the project.
 * @param {string} find - The search query to filter the documents.
 * @returns {Promise<Object>} - The response from the API containing the list of documents.
 * @throws Will throw an error if the request fails.
 */
export async function Availabledocuments(
  token,
  user,
  unit,
  role,
  id,
  owner,
  find
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }

  const response = await axios.get(
    `/available-documents?id=${id}&hint_owner=${owner}&find=${find}`,
    config
  )
  return response
}
