import axios from "../request"

/**
 * Superadmin only route.
 * call any api.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user identifier of the requester.
 * @param {string} unit - The unit of the requester.
 * @param {string} role - The role of the requester.
 * @param {string} route - The route to call.
 * @param {string} requestType - The type of request to make (get, post, delete).
 * @param {string} body - The body of the request, used for POST requests. Should be a JSON string.
 * @returns {Promise<Object>} - The response from the API.
 * @throws Will throw an error if the request fails.
 */
export async function callAdminApi(
  token,
  user,
  unit,
  role,
  route,
  requestType,
  body = ""
) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
      "Content-Type": "application/json", // Ensure JSON content type
    },
  }

  let response
  let parsedBody

  if (body) {
    parsedBody = JSON.parse(body)
  }

  if (requestType === "get") {
    response = await axios.get(route, config)
  } else if (requestType === "post") {
    response = await axios.post(route, parsedBody, config)
  } else if (requestType === "delete") {
    response = await axios.delete(route, config)
  } else {
    throw new Error(`Unsupported request type: ${requestType}`)
  }

  return response
}

/**
 * Superadmin only route.
 * Fetches the list of all units.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user identifier of the requester.
 * @param {string} role - The role of the requester.
 * @returns {Promise<Object>} - The response from the API containing the list of available users.
 * @throws Will throw an error if the request fails.
 */
export async function fetchUnits(token, user, unit, role) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(`/available-units`, config)
  return response
}
