import React from "react"
import { Button } from "antd"

/**
 * A custom button component with additional props for enhanced functionality and styling.
 * @param {Object} props - The props object containing various configurations for the button.
 * @param {string} props.id - The id attribute of the button.
 * @param {boolean} props.isDisabled - Whether the button is disabled.
 * @param {boolean} props.isLoading - Whether the button is in a loading state.
 * @param {function} props.onClick - The function to be called when the button is clicked.
 * @param {string} props.type - The type of button. If "form", it will be styled as a primary button.
 * @param {string} props.className - Additional CSS classes to be applied to the button.
 * @param {string} props.htmlType - The type of HTML button.
 * @param {string} props.size - The size of the button.
 * @returns {JSX.Element} A React element representing the custom button component.
 */
const DataQButton = (props) => {
  return (
    <Button
      id={props.id || ""}
      disabled={props.isDisabled}
      loading={props.isLoading}
      onClick={props.onClick}
      type={props.type === "form" ? "primary" : props.type}
      className={`${props.className} ${props.type} dataq-btn-base pointer-events-auto`}
      htmlType={props.htmlType}
      size={props.size}
    >
      {props.children}
    </Button>
  )
}

export default DataQButton
