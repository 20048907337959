import React from "react"
/**
 * PreviewTemplate component renders the output of a template in a styled container.
 *
 * @param {Object} templateOutput - The component props.
 * @param {string} templateOutput.templateOutput - The output string of the template to be previewed.
 * @returns {JSX.Element} The rendered PreviewTemplate component.
 */
const PreviewTemplate = ({ templateOutput }) => {
  return (
    <div className="flex flex-col">
      <div className="whitespace-pre-wrap">{templateOutput}</div>
    </div>
  )
}

export default PreviewTemplate
