import { useState } from "react"
import { useKindeAuth } from "@kinde-oss/kinde-auth-react"
import { useRecoilValue } from "recoil"
import { userAtom } from "atoms/userAtom"

/**
 * Custom hook for making API calls with loading state management.
 *
 * @param {Function} apiCall - The API call function to be executed.
 * @returns {[Function, boolean]} - A tuple containing the API call function and the loading state.
 */

const useApiCall = (apiCall) => {
  const [isLoading, setIsLoading] = useState(false)
  const { getToken, getClaim } = useKindeAuth()
  const user = useRecoilValue(userAtom)
  const userRoles = getClaim("roles")

  /**
   * Executes the API call with the provided parameters.
   *
   * @param {...any} params - The parameters to pass to the API call function.
   * @returns {Promise<any>} - The result of the API call.
   * @throws Will throw an error if the API call fails.
   */
  const callApi = async (...params) => {
    setIsLoading(true)
    const token = await getToken()
    try {
      const res = await apiCall(
        token,
        user.username,
        user.unit,
        userRoles.value[0].name,
        ...params
      )
      setIsLoading(false)
      return res
    } catch (error) {
      setIsLoading(false)
      console.error(`Error in API call:`, error)
      throw error
    }
  }

  return [callApi, isLoading]
}

export default useApiCall
