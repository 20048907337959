import React, { useState, useEffect } from "react"
import { useRecoilValue } from "recoil"
import { Tooltip } from "antd"
import { useDataImport } from "hooks/useDataImport"

import DataImportTable from "components/Home/DataImport/DataImportTable/DataImportTable.component"
import DataQButton from "components/UI/Buttons/DataQButton"
import ProgressModal from "components/UI/ProgressModal/ProgressModal"

import { projectAtom } from "atoms/projectAtom"

import {
  ErrorNotification,
  SuccessNotification,
} from "components/UI/Notifications/NotificationTemplate.component"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"

/**
 * DataImportContainer component handles the logic for checking and combining data files.
 * It interacts with various hooks and components to ensure data is correctly imported and combined.
 *
 * @component
 * @returns {React.ReactElement} The rendered DataImportContainer component.
 */
const DataImportContainer = () => {
  const project = useRecoilValue(projectAtom)
  const {
    getDataFilesUploaded,
    isGettingDataFilesUploaded,
    combineDataFiles,
    isCombiningDataFiles,
  } = useDataImport()
  const [dataFiles, setDataFiles] = useState([])

  /**
   * useEffect hook that triggers the checkDataFiles function when the component mounts.
   */
  useEffect(() => {
    checkDataFiles()
  }, [])

  /**
   * Function to check if data files have been uploaded for the current project.
   * If successful, it updates the state with the data files.
   * In case of an error, it displays an error notification.
   *
   * @async
   * @function
   */
  const checkDataFiles = async () => {
    try {
      const isMapFile = await getDataFilesUploaded(
        project.id,
        project.Created_by,
        "map",
        "size"
      )
      const isDataFile = await getDataFilesUploaded(
        project.id,
        project.Created_by,
        "csv",
        "size"
      )
      const isJsonFile = await getDataFilesUploaded(
        project.id,
        project.Created_by,
        "json",
        "size"
      )

      const dataFiles = [
        {
          key: "data",
          fileType: "Data File",
          isUploaded: !!isDataFile.data,
          type: "csv",
        },
        {
          key: "map",
          fileType: "Map File",
          isUploaded: !!isMapFile.data,
          type: "map",
        },
        {
          key: "result",
          fileType: "Data(Q) Data file",
          isUploaded: !!isJsonFile.data,
          type: "json",
        },
      ]

      setDataFiles(dataFiles || [])
    } catch (error) {
      ErrorNotification("Error checking data files!", error.message)
    }
  }

  /**
   * Function to combine the data files if both required files (.data.csv and .map.csv) are present.
   * Displays a success notification on successful combination, otherwise displays an error notification.
   *
   * @async
   * @function
   */
  const combineDataWithMap = async () => {
    try {
      const combineRes = await combineDataFiles(project.id)
      if (combineRes.data === "Data Combined Successfully!") {
        SuccessNotification("Data Combined Successfully!", combineRes.data)
        checkDataFiles()
      } else {
        ErrorNotification("Error combining data files!", combineRes)
        checkDataFiles()
      }
    } catch (combineError) {
      ErrorNotification("Error combining data files!", combineError)
    }
  }

  const hasBothFiles =
    dataFiles.some((file) => file.type === "csv" && file.isUploaded) &&
    dataFiles.some((file) => file.type === "map" && file.isUploaded)

  const hasResultFile = dataFiles.some(
    (file) => file.type === "json" && file.isUploaded
  )

  return (
    <>
      <ProgressModal
        title={"Combining Data Files"}
        isLoading={isCombiningDataFiles}
        duration={3200}
      />
      <div className="flex flex-col h-full overflow-y-auto w-full">
        <DataImportTable
          refresh={checkDataFiles}
          isLoading={isGettingDataFilesUploaded}
          dataFiles={dataFiles}
        />
        {!hasResultFile && hasBothFiles && (
          <div className="flex p-5 ml-auto">
            <DataQButton type="primary" onClick={combineDataWithMap}>
              Combine Data Files
            </DataQButton>
            <Tooltip
              title="Combine the data and map files to generate the Data(Q) data file. Or upload the Data(Q) data file directly if you have it above."
              color={"black"}
              className="ml-2 my-auto"
            >
              <FontAwesomeIcon
                icon={faCircleExclamation}
                color="gold"
                className="cursor-pointer"
              />
            </Tooltip>
          </div>
        )}
      </div>
    </>
  )
}

export default DataImportContainer
