import React, { useState } from "react"
import { List, Checkbox, Divider, Input, Select, message } from "antd"
import { useRecoilState } from "recoil"
import { userAtom } from "atoms/userAtom"
import DataQButton from "components/UI/Buttons/DataQButton"

/**
 * SuperadminDisplay component provides an interface for selecting a unit and making API calls.
 * It allows users to specify the request type, route, and request body, and displays the API response.
 *
 * @param {Object} props - The properties object.
 * @param {Array<string>} props.units - The list of units available for selection.
 * @param {boolean} props.isLoading - Flag indicating whether the units are currently being loaded.
 * @param {Function} props.makeAdminApi - Function to make an API call, which accepts route, requestType, and body as arguments.
 * @param {boolean} props.isCallingAdminApi - Flag indicating whether an API call is currently in progress.
 * @returns {JSX.Element} The rendered JSX element.
 */
const SuperadminDisplay = (props) => {
  const { units, isLoading, makeAdminApi, isCallingAdminApi } = props
  const [user, setUser] = useRecoilState(userAtom)
  const [route, setRoute] = useState("")
  const [body, setBody] = useState("")
  const [output, setOutput] = useState("")
  const [requestType, setRequestType] = useState("get")

  /**
   * Handles the selection of a unit and updates the user atom state with the selected unit.
   *
   * @param {string} unit - The unit selected by the user.
   */
  const handleUnitSelection = (unit) => {
    setUser((prev) => ({
      ...prev,
      unit,
    }))
    message.success(`Unit set to ${unit}`)
  }

  /**
   * Handles making the API call with the specified route, request type, and body.
   * Updates the output state with the response or error message.
   *
   * @param {string} route - The API route to call.
   * @param {string} requestType - The type of request to make (get, post, delete).
   * @param {string} body - The request body in JSON format (if any).
   */
  const handleApiCall = async (route, requestType, body) => {
    try {
      const res = await makeAdminApi(route, requestType, body)
      // Check if res is a string or an object and handle accordingly
      if (typeof res.data === "string") {
        setOutput(res.data)
      } else if (typeof res.data === "object") {
        setOutput(JSON.stringify(res.data, null, 2)) // Format the object as a pretty-printed JSON string
      } else {
        setOutput("Unexpected response type.")
      }
    } catch (error) {
      console.error("Error in API call:", error)
      setOutput(`Error: ${error.message}`)
    }
  }

  /**
   * Handles changing the request type (GET, POST, DELETE) in the select dropdown.
   *
   * @param {string} value - The selected request type.
   */
  const handleRequestTypeChange = (value) => {
    setRequestType(value)
  }

  return (
    <div className="flex flex-col p-5">
      <div className="bg-white rounded-md">
        <div className="flex flex-col p-2">
          <h1 className="text-2xl font-semibold m-0">Unit Selector</h1>
          <p className="text-lg font font-semibold mt-5">
            Current Unit: {user.unit}
          </p>
        </div>

        <List
          loading={isLoading}
          className="max-h-96 overflow-auto"
          itemLayout="horizontal"
          dataSource={units}
          renderItem={(item) => (
            <List.Item
              onClick={() => handleUnitSelection(item)}
              className="cursor-pointer hover:bg-gray-100 mx-1"
              actions={[
                <Checkbox
                  key={item}
                  onClick={() => handleUnitSelection(item)}
                  className="mr-2"
                  checked={user.unit === item}
                />,
              ]}
            >
              <List.Item.Meta className="p-2 ml-2" title={item} />
            </List.Item>
          )}
        />
        <Divider />
        <div className="flex flex-col p-2">
          <h1 className="text-2xl font-semibold mt-0">API Queries</h1>
        </div>
        <div className="flex flex-row h-full px-2 space-x-2">
          <div className="flex flex-col w-full">
            <Input
              onChange={(e) => setRoute(e.target.value)}
              placeholder="Enter API Query here eg. /available-users"
            />
            <div className="flex flex-row mt-2 space-x-2">
              <Select
                style={{ width: 120 }}
                defaultValue={"get"}
                onChange={handleRequestTypeChange}
                options={[
                  { value: "get", label: "GET" },
                  { value: "post", label: "POST" },
                  { value: "delete", label: "DELETE" },
                ]}
              />
              <Input.TextArea
                placeholder="Enter request body here in JSON (if any) eg { 'name': 'hello', 'goal': 'hello' }"
                onChange={(e) => setBody(e.target.value)}
                autoSize={{ minRows: 5, maxRows: 10 }}
              />
            </div>
          </div>
          <div className="flex flex-col w-full">
            <Input.TextArea
              placeholder="Output will be displayed here"
              value={output}
              autoSize={{ minRows: 5, maxRows: 10 }}
            />
          </div>
        </div>
        <DataQButton
          onClick={() => handleApiCall(route, requestType, body)}
          className="m-2"
          type="primary"
          loading={isCallingAdminApi}
        >
          Run API
        </DataQButton>
      </div>
    </div>
  )
}

export default SuperadminDisplay
