import React from "react"
import DataQButton from "components/UI/Buttons/DataQButton"

/**
 * Function to generate columns for the document table.
 *
 * @param {Function} deleteProjectDocument - Function to delete a document.
 * @param {Function} downloadProjectDocument - Function to download a document.
 * @returns {Array} The array of column objects.
 */
const getColumns = (deleteProjectDocument, downloadProjectDocument) => [
  {
    title: "Document",
    key: "name",
    render: (_, record) => <p>{record}</p>,
  },
  {
    title: <div style={{ textAlign: "left" }}>Actions</div>, // Title aligned to the left
    key: "actions",
    align: "right", // Content aligned to the right
    width: 200, // Fixed width for the actions column
    render: (_, record) => (
      <div className="space-x-2">
        <DataQButton
          type="primary"
          onClick={() => downloadProjectDocument(record)}
        >
          Download
        </DataQButton>
        <DataQButton
          type="danger"
          onClick={() => deleteProjectDocument(record)}
        >
          Delete
        </DataQButton>
      </div>
    ),
  },
]

export default getColumns
