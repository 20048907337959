/**
 * Home container component responsible for acting as a container for the homepage.
 * @namespace HomeContainer
 * @memberOf Home
 */
import React, { useEffect, useState, useCallback } from "react"
import { useRecoilValue } from "recoil"

import ProjectDisplay from "components/Home/Projects/ProjectDisplay.component"
import {
  ErrorNotification,
  SuccessNotification,
} from "components/UI/Notifications/NotificationTemplate.component"

import { useProjects } from "hooks/useProjects"
import { useUser } from "hooks/useUser"

import { userAtom } from "atoms/userAtom"

import handleSequenceError from "utils/handleSequenceError"

/**
 * Renders the Home container component.
 * This component serves as the main page of the application, showcasing a list of projects.
 * It utilizes the ProjectDisplay component to render the projects passed to it via props.
 *
 * @function HomeContainer
 * @memberof HomeContainer
 * @returns {React.ReactNode} The HomeContainer component rendered as a React node.
 */
const HomeContainer = () => {
  const {
    getUserProjects,
    isLoadingProjects,
    getArchivedProjects,
    isLoadingArchivedProjects,
    unarchiveUserProject,
    archiveUserProject,
  } = useProjects()
  const { getUserData } = useUser()
  const user = useRecoilValue(userAtom)

  const [filter, setFilter] = useState("owned")
  const [dataSource, setDataSource] = useState([])
  const [archivedProjects, setArchivedProjects] = useState([])

  useEffect(() => {
    if (!user.username || !user.unit) {
      getUserData()
    }
  }, [])

  useEffect(() => {
    if (user.username && user.unit) {
      fetchProjects()
    }
  }, [user.username, user.unit, filter])

  /**
   * Fetches the user's projects and updates the state.
   * @async
   * @function fetchProjects
   * @returns {Promise<void>}
   */
  const fetchProjects = useCallback(async () => {
    try {
      const res = await getUserProjects(filter)
      if (handleSequenceError(res, "Error Retrieving Projects!")) {
        setDataSource(res.data.result)
      }
    } catch (error) {
      ErrorNotification("Error Fetching Projects!", error.message)
    }
  }, [filter, getUserProjects])

  /**
   * Fetches the user's Archived projects and updates the state.
   * @async
   * @function fetchArchivedProjects
   * @returns {Promise<void>}
   */
  const fetchArchivedProjects = useCallback(async () => {
    try {
      const res = await getArchivedProjects()
      if (handleSequenceError(res, "Error Retrieving Archived Projects!")) {
        setArchivedProjects(res.data.result)
      }
    } catch (error) {
      ErrorNotification("Error Fetching Archived Projects!", error.message)
    }
  }, [getArchivedProjects])

  /**
   * Handles the unarchiving of a project.
   * @async
   * @function handleUnarchive
   * @param {string} id - The project ID.
   * @param {string} owner - The project owner.
   * @returns {Promise<void>}
   */
  const handleUnarchive = async (id, owner) => {
    try {
      const res = await unarchiveUserProject(id, owner)

      if (handleSequenceError(res.data, "Error Unarchiving Project!")) {
        SuccessNotification(
          "Project Unarchived Successfully!",
          "The project has been unarchived successfully."
        )
      }
    } catch (error) {
      ErrorNotification("Error Unarchiving Project!", error.message)
    }
    fetchArchivedProjects() // Refresh the archived projects list
  }

  /**
   * Handles the archiving of a project.
   * @async
   * @function handleArchive
   * @param {string} id - The project ID.
   * @param {string} owner - The project owner.
   * @returns {Promise<void>}
   */
  const handleArchive = async (id, owner) => {
    try {
      const res = await archiveUserProject(id, owner)

      if (handleSequenceError(res.data, "Error Archiving Project!")) {
        SuccessNotification(
          "Project Archived Successfully!",
          "The project has been archived successfully."
        )
      }
    } catch (error) {
      ErrorNotification("Error Archiving Project!", error.message)
    }
    fetchProjects() // Refresh the projects list
  }

  return (
    <div id="home" className="flex flex-col">
      <ProjectDisplay
        filter={filter}
        setFilter={setFilter}
        isLoading={isLoadingProjects}
        isLoadingArchived={isLoadingArchivedProjects}
        getProjects={fetchProjects}
        getArchivedProjects={fetchArchivedProjects}
        projects={dataSource}
        archivedProjects={archivedProjects}
        handleArchive={handleArchive}
        handleUnarchive={handleUnarchive}
      />
    </div>
  )
}

export default HomeContainer
