import React, { useState } from "react"
import { Modal } from "antd"
import DataQButton from "components/UI/Buttons/DataQButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { ErrorNotification } from "components/UI/Notifications/NotificationTemplate.component"

/**
 * Modal component for language selection.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.project - The project object.
 * @param {Array<Object>} props.variants - Array of variant objects.
 * @param {Object} props.selectedVariant - The currently selected variant object.
 * @param {Function} props.generatePreview - Function to generate the preview.
 */
const PreviewLanguageSelectModal = ({
  project,
  variants,
  selectedVariant,
  generatePreview,
}) => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false)
  const [availableLanguages, setAvailableLanguages] = useState([])

  /**
   * Handles the preview click by collecting all languages from every variant
   * and opening the language selection modal.
   */
  const handlePreviewClick = () => {
    let languages = []
    // Collect all languages from every variant
    if (variants && variants.length > 0) {
      if (!selectedVariant || !selectedVariant.name) {
        ErrorNotification(
          "Can't Generate Preview For Master If Variants Exist!",
          "Please select a variant to generate a preview."
        )
        return
      }
      variants.forEach((variant) => {
        if (variant.languages && variant.languages.length > 0) {
          languages = languages.concat(variant.languages)
        }
      })
      // Include the design language if not already included
      if (
        project.Designlanguage &&
        !languages.includes(project.Designlanguage)
      ) {
        languages.push(project.Designlanguage)
      }
      // Remove duplicates
      languages = [...new Set(languages)]
      if (languages.length === 0) {
        ErrorNotification(
          "No languages available in the variants.",
          "Please check the variant languages."
        )
        return
      }
      setAvailableLanguages(languages)
      setIsLanguageModalOpen(true)
    } else {
      // No variants exist
      // Use MASTER and design language
      const masterLanguage = project.Designlanguage
      generatePreview(masterLanguage, "MASTER")
    }
  }

  /**
   * Handles the language selection from the modal.
   *
   * @function handleLanguageSelection
   * @param {string} language - The selected language.
   */
  const handleLanguageSelection = (language) => {
    setIsLanguageModalOpen(false)
    if (variants && variants.length > 0) {
      // Variants exist; use selectedVariant
      generatePreview(language, selectedVariant.name)
    }
  }

  return (
    <>
      <DataQButton type="primary" onClick={handlePreviewClick}>
        Preview
        <FontAwesomeIcon icon={faMagnifyingGlass} className="ml-2" />
      </DataQButton>
      <Modal
        title="Select Preview Language"
        open={isLanguageModalOpen}
        onCancel={() => setIsLanguageModalOpen(false)}
        footer={null}
        centered
      >
        <div className="flex flex-col items-center space-y-3 pt-5">
          {availableLanguages.map((lang) => (
            <div
              key={lang}
              onClick={() => handleLanguageSelection(lang)}
              className="w-full px-4 py-3 text-center rounded-lg  cursor-pointer transition-all duration-300 bg-white hover:bg-[#36c2ed] hover:shadow-md border border-gray-200 hover:border-[#36c3ed] hover:text-white font-semibold"
            >
              {lang}
            </div>
          ))}
          <DataQButton
            type="secondary"
            onClick={() => setIsLanguageModalOpen(false)}
            className="mt-4 ml-auto"
          >
            Cancel
          </DataQButton>
        </div>
      </Modal>
    </>
  )
}

export default PreviewLanguageSelectModal
