import React, { useState } from "react"
import { useRecoilValue } from "recoil"
import { message, Upload } from "antd"

import { useDataImport } from "hooks/useDataImport"

import DataQButton from "components/UI/Buttons/DataQButton"
import ProgressModal from "components/UI/ProgressModal/ProgressModal"
import { projectAtom } from "atoms/projectAtom"
import { calculateFileUploadDuration } from "utils/calculateFileUploadDuration"

/**
 * Button Component for importing data files.
 *
 * This component allows users to upload files by clicking or dragging them to the upload area.
 * Supported file formats are CSV and JSON.
 *
 * @returns {JSX.Element} The rendered data import component.
 */
const DataImport = ({ name, refresh, type }) => {
  const { uploadDataImportFile, isUploadingDataimportFile } = useDataImport()
  const project = useRecoilValue(projectAtom)
  const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false)
  const [modalDuration, setModalDuration] = useState(10000)

  const uploadProps = {
    name: "file",
    customRequest: async ({ file, onSuccess, onError }) => {
      try {
        const response = await uploadDataImportFile(
          project.id,
          project.Created_by,
          type,
          file
        )
        onSuccess(response)
        refresh()
      } catch (error) {
        onError(error)
        refresh()
      }
    },
    /**
     * Validates the file format before upload.
     *
     * @param {File} file - The file to be uploaded.
     * @returns {boolean|string} - Returns true if the file format is valid, otherwise returns `Upload.LIST_IGNORE`.
     */
    beforeUpload: (file) => {
      // Uses MIME format to validate file type
      const isCorrectFormat =
        file.type === "application/vnd.ms-excel" ||
        file.type === "application/json" ||
        file.type === "text/csv"
      if (!isCorrectFormat) {
        message.error(`${file.name} is not a valid file format.`)
      }

      const correctExtension =
        (type === "csv" && file.name.endsWith("data.csv")) ||
        (type === "map" && file.name.endsWith("map.csv")) ||
        (type === "json" && file.name.endsWith("json"))

      if (!isCorrectFormat || !correctExtension) {
        message.error(
          `${file.name} is not a valid file format or has an incorrect file extension.`
        )
      }
      return (isCorrectFormat && correctExtension) || Upload.LIST_IGNORE
    },
    onChange(info) {
      if (info.file.status === "uploading") {
        // Dynamically set the modal duration based on the original file size (before compression)
        const dynamicDuration = calculateFileUploadDuration(info.file.size)
        setModalDuration(dynamicDuration)
        setIsLoadingModalVisible(true)
      }
      if (info.file.status === "done") {
        setIsLoadingModalVisible(false)
        message.success(`${info.file.name} file uploaded successfully`)
        refresh()
      } else if (info.file.status === "error") {
        setIsLoadingModalVisible(false)
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  return (
    <>
      <ProgressModal
        title={"Uploading File..."}
        isLoading={isLoadingModalVisible}
        duration={modalDuration} // Set the modal duration dynamically
      />
      <Upload
        showUploadList={false}
        {...uploadProps}
        className="ml-auto"
        maxCount={1}
      >
        <DataQButton type="primary" loading={isUploadingDataimportFile}>
          {name}
        </DataQButton>
      </Upload>
    </>
  )
}

export default DataImport
