import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react"
import { useBlocker } from "react-router-dom"
import { useEditor, EditorContent } from "@tiptap/react"
import Document from "@tiptap/extension-document"
import Text from "@tiptap/extension-text"
import { Slice, Fragment, Node } from "prosemirror-model"

import FontChangeExtension from "./Extensions/FontChangeExtension"
import CustomParagraph from "./Extensions/CustomParagraphExtension"
import QuestionnaireCheckDisplay from "./QuestionnaireCheckDisplay.component"
import QuestionnaireMenuBar from "components/Home/QuestionnaireEditor/Editor/MenuBar/QuestionnaireMenuBar.component"
import EditorFooter from "./EditorFooter.component"
import ConfirmationModal from "components/UI/ConfimationModal/ConfimationModal"

import { escapeHTML } from "utils/escapeHtml"
import { useQuestionnaire } from "hooks/useQuestionnaire"

/**
 * Editor component provides a rich text editor for questionnaires.
 * It integrates with various extensions and allows for content manipulation, saving, and validation.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.questionnaire - The initial content of the questionnaire.
 * @param {Function} props.saveQuestionnaire - Function to save the questionnaire content.
 * @param {Array} props.variants - The variants data that will be passed to the export form.
 * @param {boolean} props.inMaster - Indicates if the template should be used in the master configuration.
 * @param {boolean} props.isLocked - Indicates if the questionnaire is locked.
 * @param {Function} props.getQuestionnaireNotes - Function to retrieve the notes for the questionnaire.
 * @param {Object} ref - The ref object for the component to expose methods.
 * @returns {JSX.Element} The rendered Editor component.
 */
const Editor = forwardRef((props, ref) => {
  const {
    questionnaire,
    saveQuestionnaire,
    variants,
    inMaster,
    isLocked,
    getQuestionnaireNotes,
  } = props

  const { isCheckingQuestionnaire, checkQuestionnaireByID } = useQuestionnaire()
  const editorContainerRef = useRef(null)
  const lineNumbersRef = useRef(null)
  const [highlightingEnabled, setHighlightingEnabled] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [editorContent, setEditorContent] = useState(questionnaire)
  const [checkingQuestionnaireEnabled, setCheckingQuestionnaireEnabled] =
    useState(false)
  const [checkQuestionnaireErrors, setCheckQuestionnaireErrors] = useState([])
  const [checkQuestionnaireWarnings, setCheckQuestionnaireWarnings] = useState(
    []
  )
  const [contentChanged, setContentChanged] = useState(false)
  const [lineNumbersEnabled, setLineNumbersEnabled] = useState(false)

  const qnrEmpty = questionnaire === "<p></p>"

  /**
   * Parses clipboard text into document nodes and creates a `Slice` object for insertion into a document.
   *
   * @param {string} text - The clipboard text to be parsed. Each line of text will be processed as a separate block.
   * @param {Object} context - The editor's document context, typically containing schema and doc information.
   * @param {boolean} plain - A flag indicating if the text is plain or contains rich formatting (currently not used in function).
   *
   * @returns {Slice} - A `Slice` object that contains a fragment of nodes, each representing a paragraph.
   */
  const clipboardTextParser = (text, context) => {
    const blocks = text.split(/(?:\r\n?|\n)/)
    const nodes = []

    blocks.forEach((line) => {
      let nodeJson = { type: "paragraph" }
      if (line.length > 0) {
        nodeJson.content = [{ type: "text", text: line }]
      }
      let node = Node.fromJSON(context.doc.type.schema, nodeJson)
      nodes.push(node)
    })

    const fragment = Fragment.fromArray(nodes)
    return Slice.maxOpen(fragment)
  }

  /**
   * Handles paste events in the editor, ensuring content is pasted as plain text.
   *
   * @param {Object} view - The editor view instance.
   * @param {ClipboardEvent} event - The paste event object.
   * @param {Slice} slice - The content slice being pasted (not used in this implementation).
   * @returns {boolean} Returns true to indicate the paste event was handled.
   */
  const pasteHandler = (view, event) => {
    event.preventDefault()
    const text = event.clipboardData.getData("text/plain")
    const { tr } = view.state
    const parsedSlice = clipboardTextParser(text, view.state, true)
    tr.replaceSelection(parsedSlice)
    view.dispatch(tr)
    return true
  }

  /**
   * Initializes and returns the extensions for the editor.
   *
   * @function getExtensions
   * @returns {Array} The array of editor extensions.
   */
  const getExtensions = () => [
    Document,
    Text,
    CustomParagraph,
    ...(highlightingEnabled
      ? [
          FontChangeExtension.configure({
            largeFileThreshold: 150000, // Adjust this value as needed
          }),
        ]
      : []),
  ]

  const editor = useEditor(
    {
      extensions: getExtensions(),
      content: editorContent,
      editorProps: {
        clipboardTextParser: clipboardTextParser,
        handlePaste: pasteHandler,
      },
    },
    [highlightingEnabled]
  )

  useImperativeHandle(ref, () => ({
    /**
     * Inserts content at the end of the editor.
     *
     * @function insertContentAtEnd
     * @param {string} text - The text to insert.
     */
    insertContentAtEnd: (text) => {
      if (editor) {
        const endPosition = editor.state.doc.content.size
        editor
          .chain()
          .focus()
          .insertContentAt(
            endPosition,
            text
              .split("\n")
              .map((s) => "<p>" + escapeHTML(s) + "</p>")
              .join("")
          )
          .run()
      }
    },
    /**
     * Retrieves the current HTML content of the editor.
     *
     * @function getHTML
     * @returns {string} The HTML content of the editor.
     */
    getHTML: () => {
      return editor ? editor.getHTML() : ""
    },
  }))

  // Auto save the questionnaire every 30 seconds
  useEffect(() => {
    let saveInterval

    if (editor && !isLocked) {
      saveInterval = setInterval(() => {
        setIsSaving(true)
        setEditorContent(editor.getHTML())
        saveQuestionnaire().then(() => {
          setTimeout(() => {
            setIsSaving(false)
            setContentChanged(false)
          }, 1000)
        })
      }, 30000) // 30 seconds
    }

    return () => {
      if (saveInterval) {
        clearInterval(saveInterval)
      }
    }
  }, [editor, isLocked, saveQuestionnaire])

  // Event listener to check if content has changed
  useEffect(() => {
    if (editor) {
      const updateContentChanged = () => {
        setContentChanged(true)
      }

      editor.on("update", updateContentChanged)

      return () => {
        editor.off("update", updateContentChanged)
      }
    }
  }, [editor])

  // Implement virtualization for line numbers
  useEffect(() => {
    if (!editor) return

    const lineNumbersContainer = lineNumbersRef.current
    if (!lineNumbersContainer) return

    const editorScrollContainer = editorContainerRef.current
    if (!editorScrollContainer) return

    // Adjust line numbers when the editor updates
    const renderLineNumbers = () => {
      const { scrollTop, clientHeight } = editorScrollContainer
      const scrollBottom = scrollTop + clientHeight

      const lines = editor.view.dom.querySelectorAll(".ProseMirror > *")
      const lineHeights = []
      let totalHeight = 0

      // Calculate cumulative heights to determine line positions
      lines.forEach((line) => {
        const height = line.offsetHeight
        lineHeights.push({ height, top: totalHeight })
        totalHeight += height
      })

      // Find the visible lines
      const visibleLines = []
      for (let i = 0; i < lineHeights.length; i++) {
        const { top, height } = lineHeights[i]
        if (top + height >= scrollTop && top <= scrollBottom) {
          visibleLines.push({ index: i, top, height })
        }
        if (top > scrollBottom) {
          break
        }
      }

      // Clear existing line numbers
      lineNumbersContainer.innerHTML = ""

      // Render visible line numbers
      visibleLines.forEach(({ index, top, height }) => {
        const lineNumber = document.createElement("div")
        lineNumber.className = "line-number"
        lineNumber.textContent = index + 1
        lineNumber.style.top = `${top}px`
        lineNumber.style.height = `${height}px`
        lineNumbersContainer.appendChild(lineNumber)
      })
    }

    // Initial render
    renderLineNumbers()

    // Scroll event listener with throttling
    let scrollTimeout = null
    const handleScroll = () => {
      if (scrollTimeout) return
      scrollTimeout = setTimeout(() => {
        renderLineNumbers()
        scrollTimeout = null
      }, 50) // Adjust the throttle delay as needed
    }

    editorScrollContainer.addEventListener("scroll", handleScroll)

    // Update line numbers on editor updates
    editor.on("update", renderLineNumbers)

    // Clean up
    return () => {
      editorScrollContainer.removeEventListener("scroll", handleScroll)
      editor.off("update", renderLineNumbers)
    }
  }, [editor, lineNumbersEnabled])

  /**
   * Toggles the highlighting feature in the editor.
   *
   * @function toggleHighlighting
   */
  const toggleHighlighting = () => {
    if (editor) {
      setEditorContent(editor.getHTML())
    }
    setHighlightingEnabled(!highlightingEnabled)
  }

  /**
   * Toggles the line numbers visibility in the editor.
   *
   * @function toggleLineNumbers
   */
  const toggleLineNumbers = () => {
    if (editor) {
      setEditorContent(editor.getHTML())
    }
    setLineNumbersEnabled(!lineNumbersEnabled)
  }

  /**
   * Checks the questionnaire for errors and warnings by its ID.
   *
   * @async
   * @function checkQuestionnaire
   * @param {string} id - The ID of the questionnaire to check.
   */
  const checkQuestionnaire = async (id) => {
    try {
      manualSaveQuestionnaire() //Save the content before checking
      setCheckingQuestionnaireEnabled(true)
      const response = await checkQuestionnaireByID(id)
      setCheckQuestionnaireErrors(response.data.result.notifications.errors)
      setCheckQuestionnaireWarnings(
        response.data.result.notifications.unfinished
      )
    } catch (error) {
      console.error("Error checking questionnaire:", error.message)
    }
  }

  /**
   * Manually saves the questionnaire content.
   *
   * @function manualSaveQuestionnaire
   */
  const manualSaveQuestionnaire = () => {
    if (editor) {
      setEditorContent(editor.getHTML())
    }
    setIsSaving(true)
    saveQuestionnaire().then(() => {
      setTimeout(() => {
        setIsSaving(false)
        setContentChanged(false)
      }, 1000)
    })
  }

  /**
   * Scrolls the editor to a specific line number.
   *
   * @function scrollToLine
   * @param {number} lineNumber - The line number to scroll to.
   */
  const scrollToLine = (lineNumber) => {
    const lines = editor.view.dom.querySelectorAll(".ProseMirror > *")
    if (lineNumber < 1 || lineNumber > lines.length) return // Prevent out-of-range

    const lineY = lines[lineNumber - 1].offsetTop
    editorContainerRef.current.scrollTo({
      top: lineY,
      behavior: "smooth", // Smooth scroll for better UX
    })
  }

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      contentChanged !== false &&
      currentLocation.pathname !== nextLocation.pathname
  )

  /**
   * Handles the click event on an error to scroll to the corresponding line in the editor.
   *
   * @function handleErrorClick
   * @param {number} lineNumber - The line number associated with the error.
   */
  const handleErrorClick = (lineNumber) => {
    scrollToLine(lineNumber)
  }

  return (
    <div className="flex flex-col h-full">
      <ConfirmationModal
        title="Unsaved Changes"
        isVisible={blocker.state === "blocked"}
        message="You have unsaved changes, are you sure you want to leave?"
        cancel={blocker.reset}
        confirm={blocker.proceed}
        confirmButtonText="Leave"
        confirmButtonType="danger"
      />
      <QuestionnaireMenuBar
        inMaster={inMaster}
        qnrEmpty={qnrEmpty}
        variants={variants}
        editor={editor}
        highlightingEnabled={highlightingEnabled}
        toggleHighlighting={toggleHighlighting}
        checkQuestionnaire={checkQuestionnaire}
        getQuestionnaireNotes={getQuestionnaireNotes}
        toggleLineNumbers={toggleLineNumbers}
        lineNumbersEnabled={lineNumbersEnabled}
      />
      <div className="flex flex-col flex-grow overflow-hidden">
        <div
          ref={editorContainerRef}
          className={`flex relative ${
            checkingQuestionnaireEnabled ? "h-[70%]" : "h-full"
          } overflow-y-auto w-full transition-height duration-300`}
        >
          {lineNumbersEnabled && (
            <div
              ref={lineNumbersRef}
              className="line-numbers-questionnaire border-gray-300"
              style={{ position: "absolute", left: 0, top: 0 }}
            ></div>
          )}
          <div className={lineNumbersEnabled ? "ml-10 w-full" : "w-full"}>
            <EditorContent className="h-full w-full" editor={editor} />
          </div>
        </div>

        {checkingQuestionnaireEnabled && (
          <div className="h-[30%] overflow-hidden border-t border-gray-300">
            <QuestionnaireCheckDisplay
              setCheckingQuestionnaireEnabled={setCheckingQuestionnaireEnabled}
              checkQuestionnaireErrors={checkQuestionnaireErrors}
              checkQuestionnaireWarnings={checkQuestionnaireWarnings}
              checkQuestionnaire={checkQuestionnaire}
              isCheckingQuestionnaire={isCheckingQuestionnaire}
              handleErrorClick={handleErrorClick}
            />
          </div>
        )}
      </div>
      <EditorFooter
        isLocked={isLocked}
        saveQuestionnaire={manualSaveQuestionnaire}
        isSaving={isSaving}
        contentChanged={contentChanged}
      />
    </div>
  )
})

Editor.displayName = "Editor"

export default Editor
