import React, { useState } from "react"
import { Menu, Tooltip, message } from "antd"
import { useRecoilValue } from "recoil"

import { useEngine } from "hooks/useEngine"
import { useTemplates } from "hooks/useTemplates"

import items from "./MenuItems"
import actions from "./MenuActions"

import DataQButton from "components/UI/Buttons/DataQButton"
import ProgressModal from "components/UI/ProgressModal/ProgressModal"
import ExportToDecipherModal from "./ExportToDecipher/ExportToDecipherModal.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRightToBracket,
  faListOl,
} from "@fortawesome/free-solid-svg-icons"

import { projectAtom } from "atoms/projectAtom"
import TemplatesModal from "./Templates/TemplatesModal.component"
import ExportModal from "components/UI/ExportModal/ExportModal"

/**
 * Functional component representing the menu bar for the questionnaire editor.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.inMaster - Indicates if the template should be used in the master configuration.
 * @param {boolean} props.qnrEmpty - Indicates if the questionnaire is empty.
 * @param {Object} props.editor - The editor instance used to manipulate the questionnaire.
 * @param {boolean} props.highlightingEnabled - Indicates if the highlighting is enabled.
 * @param {Function} props.toggleHighlighting - Function to toggle the highlighting feature.
 * @param {Array} props.variants - The variants data that will be passed to the export form.
 * @param {Function} props.checkQuestionnaire - Function to check the questionnaire.
 * @param {Function} props.getQuestionnaireNotes - Function to retrieve the notes for the questionnaire.
 * @param {Function} props.toggleLineNumbers - Function to toggle the line numbers in the editor.
 * @param {boolean} props.lineNumbersEnabled - Indicates if the line numbers are enabled.
 * @returns {JSX.Element} The rendered menu bar component.
 */
const QuestionnaireMenuBar = (props) => {
  const project = useRecoilValue(projectAtom)
  const {
    inMaster,
    qnrEmpty,
    editor,
    highlightingEnabled,
    toggleHighlighting,
    variants,
    checkQuestionnaire,
    getQuestionnaireNotes,
    toggleLineNumbers,
    lineNumbersEnabled,
  } = props
  const [isExportDecipherModalVisible, setExportDecipherModalVisible] =
    useState(false)
  const [isTemplateModalVisible, setTemplateModalVisible] = useState(false)
  const [isExportModalVisible, setIsExportModalVisible] = useState(false)
  const [templateFiles, setTemplateFiles] = useState({})

  const { downloadDatamapCSV, isDownloadingDatamapCSV } = useEngine()
  const { listTemplateFiles, listingTemplateFiles } = useTemplates()

  /**
   * Handles the click event on menu items.
   *
   * @param {Object} e - The click event object.
   * @param {string} e.key - The key of the clicked menu item.
   */
  const onClick = async (e) => {
    const action = actions(editor)[e.key]

    if (e.key === "template") {
      try {
        setTemplateModalVisible(true)
        const templateRes = await listTemplateFiles()
        setTemplateFiles(templateRes.data.result)
      } catch (error) {
        message.error("Template failed to be parsed!")
        console.error("Error parsing template:", error)
      }
    } else if (e.key === "export_to_decipher_xml") {
      setExportDecipherModalVisible(true)
    } else if (e.key === "download_data_map_template") {
      setIsExportModalVisible(true)
    } else if (action) {
      action()
    }
  }

  /**
   * Confirms the export of a data map template.
   *
   * @param {string} filename - The name of the file for the export.
   * @returns {Promise<void>}
   */
  const confirmExport = async (filename) => {
    try {
      await downloadDatamapCSV(project.id, filename)
      setIsExportModalVisible(false)
      message.success("Datamap created successfully!")
    } catch (error) {
      message.error("Datamap failed to be created!")
      console.error("Error creating datamap:", error)
    }
  }

  return (
    <>
      <ExportModal
        title="Download Datamap Template"
        confirm={confirmExport}
        cancel={setIsExportModalVisible}
        isVisible={isExportModalVisible}
        message={"Please enter a filename for your Datamap Template"}
      />
      <ProgressModal
        title={"Creating Datamap"}
        isLoading={isDownloadingDatamapCSV}
        duration={100}
      />
      <div className="flex flex-row w-full border">
        <ExportToDecipherModal
          getQuestionnaireNotes={getQuestionnaireNotes}
          isExportDecipherModalVisible={isExportDecipherModalVisible}
          setExportDecipherModalVisible={setExportDecipherModalVisible}
          project={project}
          variants={variants}
        />
        <TemplatesModal
          inMaster={inMaster}
          qnrEmpty={qnrEmpty}
          isTemplateModalVisible={isTemplateModalVisible}
          setTemplateModalVisible={setTemplateModalVisible}
          listingTemplateFiles={listingTemplateFiles}
          templateFiles={templateFiles}
          editor={editor}
        />
        <Menu
          className="border-gray-300 w-full"
          onClick={onClick}
          selectedKeys={[]}
          mode="horizontal"
          items={items}
        />

        <div className="flex ml-auto border-b pointer-events-auto">
          <Tooltip title="Toggle Highlighting" placement="bottomRight">
            <div className="flex">
              <DataQButton
                type={highlightingEnabled ? "primary" : "secondary"}
                className="w-7 !h-7 !p-0 my-auto mr-2"
                onClick={() => toggleHighlighting()}
              >
                (Q)
              </DataQButton>
            </div>
          </Tooltip>
          <Tooltip title="Toggle Line Numbers" placement="bottomRight">
            <div className="flex">
              <DataQButton
                type={lineNumbersEnabled ? "primary" : "secondary"}
                className="w-7 !h-7 !p-0 my-auto mr-2"
                onClick={() => toggleLineNumbers()}
              >
                <FontAwesomeIcon icon={faListOl} />
              </DataQButton>
            </div>
          </Tooltip>
          <Tooltip title="Check Questionnaire" placement="bottomRight">
            <div className="flex">
              <DataQButton
                type="secondary"
                className="w-7 !h-7 !p-0 my-auto mr-2"
                onClick={() => checkQuestionnaire(project.id)}
              >
                <FontAwesomeIcon icon={faArrowRightToBracket} />
              </DataQButton>
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  )
}

export default QuestionnaireMenuBar
