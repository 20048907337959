import React, { useState, createRef } from "react"
import { Modal } from "antd"
import Draggable from "react-draggable"
import DataQButton from "components/UI/Buttons/DataQButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLanguage } from "@fortawesome/free-solid-svg-icons"
import TranslationModalContent from "./TranslationModalContent"
import { useQuestionnaire } from "hooks/useQuestionnaire"

const TranslationModal = (props) => {
  const { saveQuestionnaire, project, session } = props
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [parseFailed, setParseFailed] = useState(false)

  const { checkQuestionnaireByID } = useQuestionnaire()

  const draggleRef = createRef()
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  })

  /**
   * Handles opening the modal and checking the questionnaire
   * @async
   */
  const handleOpenModal = async () => {
    const res = await checkQuestionnaireByID(project.id)
    if (
      res.data.result.notifications &&
      Object.keys(res.data.result.notifications).length > 0
    ) {
      setParseFailed(true)
      setIsModalVisible(true)
    } else {
      setParseFailed(false)
      setIsModalVisible(true)
    }
  }

  /**
   * Handles the start of dragging the modal
   * @param {Event} event - The drag start event
   * @param {Object} uiData - UI data for the drag event
   */
  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (!targetRect) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    })
  }

  return (
    <>
      <DataQButton onClick={handleOpenModal} type="secondary">
        Translations
        <FontAwesomeIcon icon={faLanguage} className="ml-2" />
      </DataQButton>
      <Modal
        title={
          <div className="modal-header w-full cursor-move flex items-center">
            Translation Manager
            <FontAwesomeIcon
              icon={faLanguage}
              className="mx-2 text-[#36c3ed]"
            />
          </div>
        }
        open={isModalVisible}
        destroyOnClose={true}
        mask={false}
        maskClosable={false}
        footer={null}
        width={750}
        wrapClassName="pointer-events-none"
        onCancel={() => setIsModalVisible(false)}
        modalRender={(modal) => (
          <Draggable
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
            handle=".modal-header"
          >
            <div
              className="shadow-lg rounded-lg overflow-hidden"
              ref={draggleRef}
            >
              {modal}
            </div>
          </Draggable>
        )}
      >
        <TranslationModalContent
          saveQuestionnaire={saveQuestionnaire}
          project={project}
          session={session}
          parseFailed={parseFailed}
          setIsModalVisible={setIsModalVisible}
        />
      </Modal>
    </>
  )
}

export default TranslationModal
