/**
 * @module MoveProjectModal
 * @description A modal component for moving a project to a new owner.
 */

import React, { useEffect, useState } from "react"
import { Modal, List, Checkbox } from "antd"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons"
import { useProjects } from "hooks/useProjects"
import { useUnits } from "hooks/useUnits"
import DataQButton from "components/UI/Buttons/DataQButton"
import {
  SuccessNotification,
  ErrorNotification,
} from "components/UI/Notifications/NotificationTemplate.component"
import { projectAtom } from "atoms/projectAtom"
import handleSequenceError from "utils/handleSequenceError"

/**
 * @function MoveProjectModal
 * @description A modal component for moving a project to a new owner.
 * @returns {JSX.Element} The MoveProjectModal component
 */
const MoveProjectModal = () => {
  const project = useRecoilValue(projectAtom)
  const { moveUserProject, isMovingProject } = useProjects()
  const { getUnitUsers, isLoadingUnitUsers } = useUnits()
  const navigate = useNavigate()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [userData, setUserData] = useState([])
  const [selectedOwner, setSelectedOwner] = useState("")

  useEffect(() => {
    if (project.id) {
      getUserData()
    }
  }, [project.id])

  /**
   * @async
   * @function getUserData
   * @description Fetches user data for the unit and sets it in the state.
   * @returns {Promise<void>}
   */
  const getUserData = async () => {
    try {
      const res = await getUnitUsers()
      let userArray = res.data.split("\n")
      userArray = userArray.filter(
        (user) => user.toLowerCase() !== project.Created_by.toLowerCase()
      )
      setUserData(userArray || [])
    } catch (error) {
      console.log("Error Fetching Data:", error.message)
    }
  }

  /**
   * @async
   * @function moveProject
   * @description Moves the project to a new owner.
   * @returns {Promise<void>}
   */
  const moveProject = async () => {
    if (!selectedOwner) {
      ErrorNotification("Error Moving Project!", "Please select a new owner.")
      return
    }

    try {
      const res = await moveUserProject(
        project.id,
        project.Created_by,
        selectedOwner
      )
      if (handleSequenceError(res.data, "Error Moving Project!")) {
        SuccessNotification(
          "Project Moved Successfully!",
          "The project ownership has been transferred successfully."
        )
        navigate("/home")
      }
    } catch (error) {
      ErrorNotification("Error Moving Project!", error.message)
    }
  }

  /**
   * @function showModal
   * @description Sets the modal visibility to true.
   */
  const showModal = () => {
    setIsModalVisible(true)
  }

  /**
   * @function cancel
   * @description Resets the modal state and closes it.
   */
  const cancel = () => {
    setIsModalVisible(false)
    setSelectedOwner("")
  }

  /**
   * @function handleOwnerSelect
   * @description Handles the selection of a new owner.
   * @param {string} user - The selected user
   * @returns {Function} The event handler function
   */
  const handleOwnerSelect = (user) => () => {
    setSelectedOwner(user === selectedOwner ? "" : user)
  }

  if (!userData) return null

  return (
    <>
      <DataQButton onClick={showModal} type="primary" className="text-black">
        Move Project
        <FontAwesomeIcon className="ml-2" icon={faExchangeAlt} />
      </DataQButton>
      <Modal
        open={isModalVisible}
        centered={true}
        footer={false}
        closable={false}
        destroyOnClose={true}
        width={600}
      >
        <h2 className="text-xl font-semibold m-0 mb-2 ">Select New Owner</h2>
        <List
          loading={isLoadingUnitUsers}
          itemLayout="horizontal"
          dataSource={userData}
          renderItem={(user) => (
            <List.Item
              onClick={handleOwnerSelect(user)}
              className="cursor-pointer hover:bg-gray-200 rounded-2xl"
              actions={[
                <Checkbox
                  key={user}
                  className="mr-2"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleOwnerSelect(user)()
                  }}
                  checked={selectedOwner === user}
                />,
              ]}
            >
              <List.Item.Meta className="p-2 ml-2" title={user} />
            </List.Item>
          )}
        />
        <div className="flex flex-row mt-5 justify-between">
          <DataQButton type="secondary" onClick={cancel}>
            Cancel
          </DataQButton>
          <DataQButton
            type="primary"
            onClick={moveProject}
            loading={isMovingProject}
          >
            Move Project
          </DataQButton>
        </div>
      </Modal>
    </>
  )
}

export default MoveProjectModal
