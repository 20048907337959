import * as XLSX from "xlsx"

/**
 * Imports translations from an Excel file and merges them with existing translations.
 *
 * This function reads an Excel file, processes each sheet as a separate language,
 * and updates or adds translations to the existing set. It preserves existing
 * translations that are not present in the imported file.
 *
 * @param {File} file - The Excel file to import.
 * @param {Array<Object>} currentTranslations - The current set of translations.
 * @returns {Promise<Object>} A promise that resolves to an object containing:
 *   - translations: Array<Object> - The updated set of translations.
 *   - languages: Array<string> - The list of languages found in the import.
 * @throws {Error} If there's an issue reading the file or processing the data.
 *
 * @example
 * const file = event.target.files[0];
 * const currentTranslations = [
 *   { identifier: 'greeting', MASTER: 'Hello', French: 'Bonjour' }
 * ];
 * try {
 *   const { translations, languages } = await importExcelTranslations(file, currentTranslations);
 *   console.log(translations); // Updated translations
 *   console.log(languages); // List of languages in the import
 * } catch (error) {
 *   console.error('Import failed:', error);
 * }
 */
export const importExcelTranslations = (file, currentTranslations) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: "array" })
        const importedTranslations = [...currentTranslations]
        const languages = new Set()
        // Process each sheet in the workbook
        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName]
          const sheetData = XLSX.utils.sheet_to_json(worksheet)
          sheetData.forEach((row) => {
            const identifier = row["Identifier"]
            const original = row["Original"]
            const translation = row[sheetName] || ""
            let existingItem = importedTranslations.find(
              (item) => item.identifier === identifier
            )
            if (existingItem) {
              // Update existing translation
              existingItem[sheetName] = translation
            } else {
              // Add new translation item
              existingItem = {
                identifier,
                MASTER: original,
                [sheetName]: translation,
              }
              importedTranslations.push(existingItem)
            }
            languages.add(sheetName)
          })
        })
        resolve({
          translations: importedTranslations,
          languages: Array.from(languages),
        })
      } catch (error) {
        reject(error)
      }
    }
    reader.onerror = (error) => reject(error)
    reader.readAsArrayBuffer(file)
  })
}
