import React from "react"
import { useRecoilValue } from "recoil"
import { projectAtom } from "atoms/projectAtom"

import CreateVariantModal from "./CreateVariant/CreateVariantModal.component"
import SelectVariantModal from "./SelectVariant/SelectVariantModal.component"
import TranslationModal from "../Translations/TranslationModal.component"
import PreviewLanguageSelectModal from "../Preview/PreviewLanguageSelectModal.component"

/**
 * `VariantsMenuBar` is a functional component that renders a menu bar for managing questionnaire variants.
 * The menu bar includes buttons for toggling the preview mode, selecting a variant, creating a new variant, and translation functionality.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.generatePreview - Function to generate the preview.
 * @param {Object} props.selectedVariant - The currently selected variant object.
 * @param {Function} props.setSelectedVariant - Function to set the selected variant.
 * @param {Array<Object>} props.variants - Array of variant objects.
 * @param {Function} props.setVariants - Function to set the array of variants.
 * @param {Function} props.handleDeleteVariant - Function to handle the deletion of a variant.
 * @param {Function} props.refresh - Function to refresh the project data.
 * @param {Function} props.insertVariantText - Function to insert variant text into the editor.
 * @param {Function} props.setPreviewVisible - Function to control the visibility of the preview mode.
 * @param {Function} props.saveQuestionnaire - Function to save the questionnaire.
 * @param {String} props.session - The current Questionnaire session ID.
 * @returns {JSX.Element} The rendered VariantsMenuBar component.
 */
const VariantsMenuBar = (props) => {
  const project = useRecoilValue(projectAtom)

  const {
    generatePreview,
    selectedVariant,
    setSelectedVariant,
    variants,
    setVariants,
    handleDeleteVariant,
    refresh,
    insertVariantText,
    setPreviewVisible,
    saveQuestionnaire,
    session,
  } = props

  return (
    <div className="flex flex-row border-gray-300 border justify-evenly p-2 rounded-t-md">
      <PreviewLanguageSelectModal
        project={project}
        variants={variants}
        selectedVariant={selectedVariant}
        generatePreview={generatePreview}
      />
      <SelectVariantModal
        project={project}
        variants={variants}
        handleDeleteVariant={handleDeleteVariant}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
        refresh={refresh}
        setPreviewVisible={setPreviewVisible}
      />
      <CreateVariantModal
        project={project}
        setVariants={setVariants}
        variants={variants}
        selectedVariant={selectedVariant}
        insertVariantText={insertVariantText}
        setSelectedVariant={setSelectedVariant}
        saveQuestionnaire={saveQuestionnaire}
        refresh={refresh}
      />
      <TranslationModal
        project={project}
        saveQuestionnaire={saveQuestionnaire}
        session={session}
      />
    </div>
  )
}

export default VariantsMenuBar
