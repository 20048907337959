import React, { useState, useRef, useEffect } from "react"
import { Select, Input, Upload, Tooltip } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRightToBracket,
  faGripLinesVertical,
  faFileExport,
  faFileArrowDown,
  faCircleQuestion,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons"
import { useQuestionnaire } from "hooks/useQuestionnaire"
import handleSequenceError from "utils/handleSequenceError"
import {
  ErrorNotification,
  SuccessNotification,
} from "components/UI/Notifications/NotificationTemplate.component"
import { Loader } from "components/UI/Loader/Loader"
import DataQButton from "components/UI/Buttons/DataQButton"
import { exportToExcel } from "utils/exportToExcel"
import { importExcelTranslations } from "utils/importExcelTranslations"

const { TextArea } = Input
const { Option } = Select

const TranslationModalContent = (props) => {
  const {
    saveQuestionnaire,
    project,
    session,
    parseFailed,
    setIsModalVisible,
  } = props

  const [translations, setTranslations] = useState([])
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [availableLanguages, setAvailableLanguages] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState("")
  const [
    isGettingQuestionnaireTranslations,
    setIsGettingQuestionnaireTranslations,
  ] = useState(false)

  const [columnWidths, setColumnWidths] = useState({
    master: 300,
    translation: 300,
  })

  const resizingData = useRef({
    column: null,
    startX: 0,
    startWidth: 0,
  })

  const {
    getQuestionnaireTranslationsByID,
    saveQuestionnaireTranslationsByID,
  } = useQuestionnaire()

  /**
   * Fetches translations for the current project
   * @async
   */
  const getTranslations = async () => {
    setIsGettingQuestionnaireTranslations(true)
    try {
      await saveQuestionnaire()
      const res = await getQuestionnaireTranslationsByID(
        project.id,
        project.Created_by
      )

      if (handleSequenceError(res.data, "Error Fetching Translations!")) {
        if (!res.data.result) {
          setTranslations([])
          setAvailableLanguages([])
        } else {
          const translationData = res.data.result
          setTranslations(translationData)

          // Determine available languages
          const languagesSet = new Set()
          translationData.forEach((item) => {
            Object.keys(item).forEach((key) => {
              if (
                key !== "identifier" &&
                key !== "MASTER" &&
                key !== project.Designlanguage &&
                !languagesSet.has(key)
              ) {
                languagesSet.add(key)
              }
            })
          })

          let languagesArray = Array.from(languagesSet)

          setAvailableLanguages(languagesArray)
          if (languagesArray.length > 0) {
            setSelectedLanguage(languagesArray[0])
          } else {
            setSelectedLanguage("")
          }
        }
      }
    } catch (error) {
      ErrorNotification("Error Fetching Translation!", error.message)
    }
    setIsGettingQuestionnaireTranslations(false)
  }

  /**
   * React effect to fetch translations on mount
   */
  useEffect(() => {
    if (!parseFailed) {
      getTranslations()
    }
  }, [parseFailed])

  /**
   * Handles the initialization of the column resize
   */
  const initResize = (e, column) => {
    e.preventDefault()
    resizingData.current = {
      column,
      startX: e.clientX,
      startWidth: columnWidths[column],
    }
    window.addEventListener("mousemove", handleResize)
    window.addEventListener("mouseup", stopResize)
  }

  /**
   * Handles the resizing of the column
   */
  const handleResize = (e) => {
    e.preventDefault()
    if (!resizingData.current.column) return

    const deltaX = e.clientX - resizingData.current.startX
    const newWidth = resizingData.current.startWidth + deltaX

    setColumnWidths((prevWidths) => {
      const updatedWidths = { ...prevWidths }
      updatedWidths[resizingData.current.column] = newWidth > 50 ? newWidth : 50 // Minimum width
      return updatedWidths
    })
  }

  /**
   * Stops the resizing operation
   */
  const stopResize = () => {
    resizingData.current = { column: null, startX: 0, startWidth: 0 }
    window.removeEventListener("mousemove", handleResize)
    window.removeEventListener("mouseup", stopResize)
  }

  /**
   * Handles changing the selected language
   * @param {string} value - The selected language
   */
  const handleLanguageChange = (value) => {
    setSelectedLanguage(value)
  }

  /**
   * Handles changing a translation directly in the table
   * @param {string} identifier - The identifier of the translation
   * @param {string} newValue - The new translation value
   */
  const handleTranslationChange = (identifier, newValue) => {
    setTranslations((prevTranslations) =>
      prevTranslations.map((item) =>
        item.identifier === identifier
          ? { ...item, [selectedLanguage]: newValue }
          : item
      )
    )
    setHasUnsavedChanges(true)
  }

  /**
   * Saves the current translations
   * @async
   */
  const handleSaveTranslations = async () => {
    try {
      await saveQuestionnaire()
      const res = await saveQuestionnaireTranslationsByID(
        project.id,
        project.Created_by,
        session,
        translations
      )
      if (handleSequenceError(res.data, "Error Saving Translations!")) {
        SuccessNotification(
          "Translations Saved Successfully!",
          "Translations have been saved successfully!"
        )
      }
      setHasUnsavedChanges(false)
    } catch (error) {
      ErrorNotification("Error Saving Translation!", error.message)
    }
  }

  /**
   * Renders the main content of the modal
   * @returns {JSX.Element}
   */
  const renderModalContent = () => {
    if (parseFailed) {
      return renderParseFailedContent()
    }

    if (isGettingQuestionnaireTranslations) {
      return renderLoadingContent()
    }

    return renderTranslationsContent()
  }

  /**
   * Renders the content shown when parsing fails
   * @returns {JSX.Element}
   */
  const renderParseFailedContent = () => (
    <div className="shadow-sm overflow-hidden flex-grow flex flex-col">
      <p className="text-md font-semibold">
        Parsing of the questionnaire failed!
      </p>
      <p className="text-md">
        Make sure there are no errors before trying to access translations
      </p>
      <p className="text-md">
        Use the check questionnaire button{" "}
        <FontAwesomeIcon
          className="text-[#36c3ed] mr-2"
          icon={faArrowRightToBracket}
        />
        to see the errors
      </p>
    </div>
  )

  /**
   * Renders the loading content
   * @returns {JSX.Element}
   */
  const renderLoadingContent = () => (
    <div className="flex flex-col items-center justify-center h-full">
      <Loader fontSize={48} color="#36C3ED" />
      Loading Translations...
    </div>
  )

  /**
   * Renders the translations content
   * @returns {JSX.Element}
   */
  const renderTranslationsContent = () => {
    if (!translations || translations.length === 0) {
      return (
        <div>
          <p>
            No translations available. Please add content (questions/text etc.)
            and variants with different languages into the questionnaire to
            create translations.
          </p>
        </div>
      )
    }
    /**
     * Handles the export of translations to an Excel file.
     *
     * This function checks if translations are available, and if so, it calls the exportToExcel
     * function to create and download an Excel file containing the translations. It also
     * handles success and error notifications.
     *
     * @function
     * @throws {Error} If the export process fails
     */
    const handleExportTranslations = () => {
      if (!translations || translations.length === 0) {
        ErrorNotification(
          "Export Failed",
          "No translations available to export."
        )
        return
      }

      try {
        const fileName = `translations_${project.Name}`
        exportToExcel(translations, fileName, {
          designLanguage: project.Designlanguage,
        })
        SuccessNotification(
          "Export Successful",
          `Translations exported to ${fileName}.xlsx`
        )
      } catch (error) {
        ErrorNotification("Export Failed", error.message)
      }
    }

    /**
     * Handles the import of translations from an Excel file.
     *
     * @param {File} file - The uploaded file
     */
    const handleImportTranslations = async (file) => {
      if (!file) {
        ErrorNotification("Import Failed", "No file selected.")
        return
      }

      try {
        const { translations: importedTranslations, languages } =
          await importExcelTranslations(file, translations)

        setTranslations(importedTranslations)

        // Update available languages
        setAvailableLanguages((prevLanguages) => {
          const newLanguages = new Set([...prevLanguages, ...languages])
          return Array.from(newLanguages)
        })

        SuccessNotification(
          "Import Successful",
          "Translations have been imported successfully!"
        )
        setHasUnsavedChanges(true)
      } catch (error) {
        ErrorNotification("Import Failed", error.message)
      }
    }

    // Filter translations to include only those that have the selected language
    const filteredTranslations = translations.filter(
      (item) => selectedLanguage in item
    )

    return (
      <div className="overflow-auto">
        <div className="flex items-center mb-4 justify-between">
          <div className="flex items-center">
            <span className="mr-2">Select Language:</span>
            <Select
              value={selectedLanguage}
              onChange={handleLanguageChange}
              style={{ width: 200 }}
            >
              {availableLanguages.map((lang) => (
                <Option key={lang} value={lang}>
                  {lang}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex ">
            <Tooltip title="Export translations to Excel. You can edit and re-import this file later using the import button.">
              <FontAwesomeIcon
                icon={faCircleQuestion}
                className="text-[#36c3ed]  h-4 mr-2 my-auto"
              />
            </Tooltip>
            <DataQButton
              onClick={handleExportTranslations}
              type="secondary"
              isDisabled={translations.length === 0}
            >
              Export
              <FontAwesomeIcon icon={faFileExport} className="ml-2" />
            </DataQButton>
            <Upload
              accept=".xlsx, .xls"
              showUploadList={false}
              beforeUpload={(file) => {
                handleImportTranslations(file)
                return false // Prevent automatic upload
              }}
            >
              <DataQButton type="secondary" className="mx-2">
                Import
                <FontAwesomeIcon icon={faFileArrowDown} className="ml-2" />
              </DataQButton>
            </Upload>
          </div>
        </div>
        {selectedLanguage ? (
          filteredTranslations.length > 0 ? (
            <table
              className="w-full border-collapse"
              style={{ tableLayout: "fixed" }}
            >
              <thead className="sticky top-0 bg-gray-100">
                <tr className="bg-gray-100">
                  <th
                    className="p-2 border relative"
                    style={{ width: columnWidths.master }}
                  >
                    <div className="flex items-center justify-between">
                      <span>Original</span>
                      <FontAwesomeIcon
                        icon={faGripLinesVertical}
                        className="resize-icon"
                        onMouseDown={(e) => initResize(e, "master")}
                      />
                    </div>
                  </th>
                  <th
                    className="p-2 border relative"
                    style={{ width: columnWidths.translation }}
                  >
                    <div className="flex items-center justify-between">
                      <span>{selectedLanguage}</span>
                      <FontAwesomeIcon
                        icon={faGripLinesVertical}
                        className="resize-icon"
                        onMouseDown={(e) => initResize(e, "translation")}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredTranslations.map((item) => (
                  <tr key={item.identifier}>
                    <td
                      className="p-2 border"
                      style={{ width: columnWidths.master }}
                    >
                      {item.MASTER}
                    </td>
                    <td
                      className="p-2 border"
                      style={{ width: columnWidths.translation }}
                    >
                      <TextArea
                        value={item[selectedLanguage]}
                        onChange={(e) =>
                          handleTranslationChange(
                            item.identifier,
                            e.target.value
                          )
                        }
                        autoSize={{ minRows: 1, maxRows: 6 }}
                        className="border-none"
                        style={{ width: "100%" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No translations available for {selectedLanguage}</div>
          )
        ) : (
          <div>No languages available to translate.</div>
        )}
      </div>
    )
  }

  return (
    <div className="bg-white flex flex-col max-h-[calc(100vh-300px)]">
      <div className="flex-grow overflow-auto px-1">{renderModalContent()}</div>
      <div className="flex justify-end mt-4 space-x-2 bg-white pt-2 pb-4 px-4">
        {hasUnsavedChanges && (
          <div className="flex items-center text-yellow-600">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            <span>You have unsaved changes</span>
          </div>
        )}
        <DataQButton
          type="primary"
          isDisabled={
            parseFailed ||
            isGettingQuestionnaireTranslations ||
            !translations ||
            translations.length === 0 ||
            !selectedLanguage
          }
          onClick={handleSaveTranslations}
        >
          Save Translations
        </DataQButton>
        <DataQButton onClick={() => setIsModalVisible(false)} type="secondary">
          Cancel
        </DataQButton>
      </div>
    </div>
  )
}

export default TranslationModalContent
