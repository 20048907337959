import React, { useEffect, useState } from "react"
import SuperadminDisplay from "components/Superadmin/SuperadminDisplay.component"
import { useSuperadmin } from "hooks/useSuperadmin"
import handleSequenceError from "utils/handleSequenceError"
import { ErrorNotification } from "components/UI/Notifications/NotificationTemplate.component"

/**
 * SuperadminContainer component is responsible for fetching the units from the sequence
 * and rendering the SuperadminDisplay component. It handles API calls and manages the state
 * related to the units and API loading indicators.
 *
 * @component
 * @returns {JSX.Element} The rendered SuperadminContainer component.
 */
const SuperadminContainer = () => {
  const {
    getSequenceUnits,
    isLoadingSequenceUnits,
    makeAdminApi,
    isCallingAdminApi,
  } = useSuperadmin()
  const [units, setUnits] = useState([])

  /**
   * Fetches the sequence units from the API and updates the state with the unit list.
   * If an error occurs, it displays an error notification.
   */
  const fetchUnits = async () => {
    try {
      const res = await getSequenceUnits()
      if (!res.data) {
        setUnits([])
        return
      }
      if (handleSequenceError(res.data, "Error Retrieving Units!")) {
        let unitArray = res.data.split("\n")
        setUnits(unitArray)
      }
    } catch (error) {
      ErrorNotification("Error Fetching Units!", error.message)
    }
  }

  // Fetch units on component mount
  useEffect(() => {
    fetchUnits()
  }, [])

  return (
    <div id="superadmin" className="flex flex-col">
      <SuperadminDisplay
        isLoading={isLoadingSequenceUnits}
        units={units}
        makeAdminApi={makeAdminApi}
        isCallingAdminApi={isCallingAdminApi}
      />
    </div>
  )
}

export default SuperadminContainer
