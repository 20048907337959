import axios from "../request"

/**
 * Fetches the list of available users in a users unit.
 *
 * @param {string} token - The authorization token.
 * @param {string} user - The user identifier of the requester.
 * @param {string} unit - The unit identifier for which to fetch users.
 * @param {string} role - The role of the requester.
 * @returns {Promise<Object>} - The response from the API containing the list of available users.
 * @throws Will throw an error if the request fails.
 */
export async function fetchUnitUsers(token, user, unit, role) {
  const config = {
    headers: {
      token,
      user,
      unit,
      role,
    },
  }
  const response = await axios.get(`/available-users`, config)
  return response
}
