import React from "react"
import { Table, message } from "antd"
import getColumns from "./columns"
import { useDocument } from "hooks/useDocument"
import handleSequenceError from "utils/handleSequenceError"

/**
 * Component for displaying a table of documents.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.documents - The array of document objects to display in the table.
 * @param {boolean} props.isLoading - The loading state of the table.
 * @param {Object} props.project - The project object.
 * @param {Function} props.refresh - The function to refresh the document list.
 *
 * @returns {JSX.Element} The rendered table component.
 */
const DocumentTable = (props) => {
  const { project, documents, isLoading, refresh } = props
  const { deleteDocument, downloadDocument } = useDocument()

  const deleteProjectDocument = async (filename) => {
    try {
      const response = await deleteDocument(
        project.id,
        project.Created_by,
        filename
      )
      if (handleSequenceError(response, "Error Deleting Document!")) {
        refresh()
        message.success(`${filename} file deleted successfully`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const downloadProjectDocument = async (filename) => {
    try {
      const response = await downloadDocument(
        project.id,
        project.Created_by,
        filename
      )
      if (handleSequenceError(response, "Error Downloading Document!")) {
        message.success(`${filename} file downloaded successfully`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Table
      loading={isLoading}
      size="small"
      className="w-full"
      rowClassName="cursor-pointer"
      rowKey={(record) => record} // Use the document filename as the key
      dataSource={documents}
      columns={getColumns(deleteProjectDocument, downloadProjectDocument)}
    />
  )
}

export default DocumentTable
