import React, { useState } from "react"
import { Modal, Input, Collapse, Select, Form } from "antd"
import { useEngine } from "hooks/useEngine"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExport } from "@fortawesome/free-solid-svg-icons"
import DataQButton from "components/UI/Buttons/DataQButton"

/**
 * ExportDataModal component allows exporting data (JSON, SPSS, SQL)
 * with custom filename, types, and configurations.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Object} props.queryInstance - The instance containing the data to export
 * @returns {JSX.Element} A modal that provides options to export data in various formats
 */
const ExportDataModal = (props) => {
  const { queryInstance } = props
  const [inputValues, setInputValues] = useState({
    filename: "",
    variableType: "",
    sqlType: "",
    projectName: "",
  })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { exportJSON, exportSQL, exportSPS } = useEngine()

  const [spssForm] = Form.useForm() // Form for SPSS
  const [sqlForm] = Form.useForm() // Form for SQL
  const [filenameForm] = Form.useForm() // Form for filename input

  /**
   * Handles the change in the input fields dynamically.
   *
   * @param {string} key - The key to update in the state.
   * @param {string} value - The new value to update.
   */
  const handleInputChange = (key, value) => {
    setInputValues((prev) => ({ ...prev, [key]: value }))
  }

  /**
   * Validates the filename field.
   *
   * @returns {boolean} - True if the filename is valid, false otherwise.
   */
  const validateFilename = async () => {
    try {
      await filenameForm.validateFields()
      return true
    } catch (error) {
      console.error("Filename validation failed:", error)
      return false
    }
  }

  /**
   * Handles form validation and export action.
   *
   * @param {Function} exportFunction - The export function to call on successful validation.
   * @param {Object} exportParams - The parameters to pass to the export function.
   */
  const handleExport = async (exportFunction, exportParams, form) => {
    const isFilenameValid = await validateFilename() // Ensure filename validation happens first
    if (!isFilenameValid) return // Stop if filename is invalid

    try {
      await form.validateFields() // Validate form fields for specific form
      exportFunction(queryInstance, ...exportParams)
    } catch (error) {
      console.error("Form validation failed:", error)
    }
  }

  /**
   * Exports both the SPS and DAT files in sequence.
   */
  const handleSPSSExport = async () => {
    const isFilenameValid = validateFilename() // Validate filename first
    if (!isFilenameValid) return // Stop if filename is invalid

    try {
      await spssForm.validateFields() // Validate SPSS fields

      // First, export the SPS file
      await handleExport(
        exportSPS,
        [inputValues.variableType, "sps", inputValues.filename],
        spssForm
      )

      // Then, export the DAT file
      await handleExport(
        exportSPS,
        [inputValues.variableType, "dat", inputValues.filename],
        spssForm
      )
    } catch (error) {
      console.error("SPSS export failed:", error)
    }
  }

  // Define the items for the Collapse
  const items = [
    {
      key: "1",
      label: "Export as JSON",
      children: (
        <DataQButton
          type="primary"
          onClick={() => {
            handleExport(exportJSON, [inputValues.filename], filenameForm)
          }}
        >
          Export JSON
        </DataQButton>
      ),
    },
    {
      key: "2",
      label: "Export as SPSS",
      children: (
        <Form layout="vertical" form={spssForm}>
          <Form.Item
            label="Select Variable Type"
            name="variableType"
            rules={[
              { required: true, message: "Please select a Variable Type" },
            ]}
          >
            <Select
              value={inputValues.variableType}
              onChange={(value) => handleInputChange("variableType", value)}
              options={[
                { value: "numbering", label: "Numbered" },
                { value: "labels", label: "Labelled" },
              ]}
              placeholder="Select Variable Type"
            />
          </Form.Item>
          <DataQButton type="primary" onClick={handleSPSSExport}>
            Export SPSS (SPS and DAT)
          </DataQButton>
        </Form>
      ),
    },
    {
      key: "3",
      label: "Export as SQL",
      children: (
        <Form layout="vertical" form={sqlForm}>
          <Form.Item
            label="Select SQL Type"
            name="sqlType"
            rules={[{ required: true, message: "Please select a SQL Type" }]}
          >
            <Select
              value={inputValues.sqlType}
              onChange={(value) => handleInputChange("sqlType", value)}
              options={[
                { value: "wide", label: "Wide" },
                { value: "narrow", label: "Narrow" },
              ]}
              placeholder="Select SQL Type"
            />
          </Form.Item>
          <Form.Item
            label="Project Name"
            name="projectName"
            rules={[{ required: true, message: "Please enter a Project Name" }]}
          >
            <Input
              value={inputValues.projectName}
              onChange={(e) => handleInputChange("projectName", e.target.value)}
              placeholder="Enter Project Name"
            />
          </Form.Item>
          <DataQButton
            type="primary"
            onClick={() => {
              handleExport(
                exportSQL,
                [
                  inputValues.sqlType,
                  inputValues.projectName,
                  inputValues.filename,
                ],
                sqlForm
              )
            }}
          >
            Export SQL
          </DataQButton>
        </Form>
      ),
    },
  ]

  return (
    <>
      <DataQButton onClick={() => setIsModalVisible(true)} type="primary">
        Export
        <FontAwesomeIcon icon={faFileExport} className="ml-2" />
      </DataQButton>
      <Modal
        centered
        title={"Export Data"}
        open={isModalVisible}
        closable={false}
        footer={null}
      >
        <Form layout="vertical" form={filenameForm}>
          <Form.Item
            label="Filename"
            name="filename"
            rules={[{ required: true, message: "Please enter a Filename" }]}
          >
            <Input
              value={inputValues.filename}
              onChange={(e) => handleInputChange("filename", e.target.value)}
              placeholder="Enter filename"
            />
          </Form.Item>
        </Form>

        {/* Collapse with items prop */}
        <Collapse accordion items={items} defaultActiveKey={["1"]} />

        <div className="flex flex-row space-x-2 mt-5">
          <DataQButton
            className="ml-auto"
            type="secondary"
            onClick={() => setIsModalVisible(false)}
          >
            Cancel
          </DataQButton>
        </div>
      </Modal>
    </>
  )
}

export default ExportDataModal
